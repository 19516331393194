.GoodsDetail{
	color: #333;
	position: relative;
	.shareFanliBg{
		position: fixed;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.3);
		z-index: 5;
		.shareFanLi{
			box-shadow:0px 0px 18px rgba(0,0,0,0.25);
			background: #fff;
			position: absolute;
			width: 390px;
			height: 285px;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			margin: auto;
			.shareFanLiTitle{
				border-bottom: 1px solid #E5E5E5;
				padding-left: 12px;
				line-height: 40px;
				img{
					cursor: pointer;
					float: right;
					margin-top: 5px;
				}
			}
			.content{
				background: #fff;
				text-align: center;
				.qcode{
					padding-top: 11px;
				}
			}
			.shareUrl{
				margin-top: 10px;
				width: 328px;
				padding: 5px;
				border: 1px solid #D9D9D9;
			}
			.copyBtn{
				margin-top: 12px;
				display: inline-block;
				cursor: pointer;
				width: 112px;
				height: 24px;
				line-height: 24px;
				text-align: center;
				color: #fff;
				border-radius: 12px;
				background: #E41135;
			}
			.copyNote{
				margin-top: 6px;
				font-size: 16px;
			}
			.copyTip{
				margin-top: 6px;
				color: #E41135;
			}
		}
	}
	.inner{
		min-height: 500px;
		position: relative;
		.Sidebar{
			top:0 !important;
		}
		.goodsInfoBox{
			margin-top: 40px;
			min-height: 570px;
			.imgBox{
				vertical-align: top;
				position: relative;
				display: inline-block;
				width: 440px;
				height: 608px;
				-webkit-user-select:none;
		    -moz-user-select:none;
		    -ms-user-select:none;
				user-select:none;
				.label{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					.labelPrice{
						position: absolute;
						bottom: 0;
						right: 0;
						width: 130px;
						height: 42px;
						display: block;
						.ant-statistic{
							font-weight: bold;
							text-align: center;
							.ant-statistic-content-prefix{
								display: inline-block;
								color: #FFF;
								font-size: 22px;
								line-height: 36px;
								font-weight: normal;
								vertical-align:bottom;
								margin-right: 0;
							}
							.ant-statistic-content-value{
								height: 16px;
								line-height: 41px;
								.ant-statistic-content-value-int{
									color: #FFFFFF;
									font-size: 32px;
								}
								.ant-statistic-content-value-decimal{
									font-size: 22px;
									color: #FFF;
									font-weight: normal;
								}
							}
						}
					}
					.imgAct{
						width: 100%;
						height: 440px;
						display: block;
						border: 1px solid #E5E5E5;
						z-index: 3;
					}
					.qiangguang{
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						margin: auto;
					}
		    }
				.imgSmallBox{
					position: absolute;
					top: 440px;
					margin-top: 21px;
					width: 440px;
					.btn{
						cursor: pointer;
						display: inline-block;
						width: 40px;
						height: 78px;
						border: 1px solid #E5E5E5;
						position: relative;
						img{
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							margin: auto;
						}
					}
					.nextBtn{
						float: right;
					}
					.prevBtn{
						float: left;
					}
					.imgSmall{
						cursor: pointer;
						margin-left: 10px;
						float: left;
						display: inline-block;
						width: 78px;
						height: 78px;
						border: 1px solid #E5E5E5;
					}
					.active{
						border: 1px solid #E41135;
					}
				}
			}
			.goodsInfo{
				vertical-align: top;
				width: 720px;
				margin-left: 30px;
				display: inline-block;
				.limitBox{
					height: 40px;
					background: url(../../images/bg_xianshiqianggou _short.png);
					.miaosha{
						color: #fff;
						font-size: 18px;
						line-height: 40px;
					}
					img{
						margin-top: -5px;
						vertical-align: middle;
					}
					.huodongTime{
						float: right;
						color: #fff;
						font-size: 14px;
						line-height: 40px;
						margin-right: 10px;
					}
					.daojishiBox{
						margin-right: 30px;
						float: right;
						display: inline-block;
						position: relative;
						height: 32px;
						margin-top: 5px;
						.maohao{
							text-align: center;
							width: 10px;
							line-height: 30px;
							height: 32px;
							font-size: 24px;
							height: 100%;
							background: #FA2D70;
							position: absolute;
						}
						.l{
							top: 0;
							right: 31px;
						}
						.r{
							top: 0;
							right: 74px;
						}
						.ant-statistic-content{
							line-height: 32px;
							font-size:18px;
							font-family:Helvetica;
						}
					}
				}
				.countryBox{
					margin-bottom: 14px;
					.countryImg{
						display: inline-block;
						width: 28px;
						height: 22px;
						border: 1px solid #E5E5E5;
						margin-right: 6px;
					}
					color: #333;
					font-size: 14px;
				}
				.title{
					margin-bottom: 15px;
					font-size: 18px;
					color: #333;
					font-weight: bold;
					line-height: 24px;
				}
				.priceTitle{
					margin-right: 20px;
					font-size: 12px;
					margin-left: 9px;
					color: #999;
					display: inline-block;
				}
				.priceBox{
					margin-bottom: 15px;
					background: #FCF7F8;
					padding: 14px 9px 14px 0px;
					.priceItem{
						height: 36px;
						line-height: 36px;
						margin-bottom: 5px;
						div{
							vertical-align: middle;
							display: inline-block;
						}
						.sysbol{
							font-size: 14px;
							color: #E41135;
							margin-top: 9px;
						}
						.price{
							font-size: 28px;
							color: #E41135;
						}
						.discount{
							margin: 0 5px;
							padding: 0 7px;
							height: 18px;
							line-height: 18px;
							border-radius: 2px;
							background: #FF6C00;
							font-size: 12px;
							color: #fff;
							font-weight: bold;
						}
						.originPrice{
							font-size: 12px;
							color: #FF6C00;
						}
			      .lineThrough{
			        text-decoration: line-through;
			        color: #666666;
			      }
						.sales{
							margin-top: 3px;
							float: right;
							font-size: 12px;
						}
						.tag{
							margin-right: 6px;
							padding: 0 7px;
							height: 20px;
							line-height: 18px;
							border-radius: 2px;
							font-size: 12px;
							color: #E41135;
							border: 1px solid #E41135;
						}
						.tagMoney{
							margin-right: 12px;
							font-size: 12px;
						}
						.tagText{
							cursor: pointer;
							font-size: 12px;
							color: #005AA0;
							img{
								margin-top: -4px;
								margin-left: 3px;
								vertical-align: middle;
							}
						}
						.redText{
							color: #E41135;
							font-size: 12px;
						}
						.couponSmall{
							margin-right: 10px;
							border-radius: 2px;
							padding: 0 10px;
							height: 20px;
							line-height: 18px;
							border: 1px solid #E41135;
							font-size: 12px;
							position: relative;
							.banyuan{
								background: #FCF7F8;
								width: 4px;
								height: 8px;
								position: absolute;
								top: 5px;
							}
							.left{
								left: -1px;
								border-radius: 0 4px 4px 0;
								border: 1px solid #E41135;
								border-left: none;
							}
							.right{
								right: -1px;
								border-radius: 4px 0 0 4px;
								border: 1px solid #E41135;
								border-right: none;
							}
						}
						.lookCoupon{
							float: right;
							position: relative;
							.couponNum{
								cursor: pointer;
								font-size: 12px;
								color: #333;
								-webkit-user-select:none;
						    -moz-user-select:none;
						    -ms-user-select:none;
						    user-select:none;
								img{
									margin-top: -2px;
									margin-left: 3px;
									vertical-align: middle;
								}
							}
							.couponBox{
								border: 1px solid #BABABA;
								width: 664px;
								height: 547px;
								background: #fff;
								position: absolute;
								right: 0;
								top: 60px;
								z-index: 6;
								.couponBoxTitle{
									width: 100%;
									height: 40px;
									background: #F2F2F2;
									font-size: 16px;
									line-height: 40px;
									padding-left: 20px;
									.close{
										margin-top: 4px;
										vertical-align: middle;
										float: right;
										cursor: pointer;
									}
								}
								.couponContent{
									width: 100%;
									height: 506px;
									overflow-y: auto;
									padding: 15px 30px;
								}
							}
						}
					}
				}
				.send{
					margin-bottom: 10px;
					div{
						font-size: 12px;
						display: inline-block;
					}
					.hasStock{
						margin-left: 12px;
						color: #E41135;
						font-size: 12px;
					}
				}
				.shareFanli{
					margin-top: 20px;
					border: 1px solid #E41135;
					background: #FCF7F8;
					height: 34px;
					line-height: 32px;
					.infoIcon{
						cursor: pointer;
						margin-left: 3px;
						margin-top: -3px;
						vertical-align: middle;
					}
					.zhuanfaImg{
						margin-right: 18px;
						margin-left: 3px;
						margin-top: -3px;
						vertical-align: middle;
					}
					.shareFanliBtn{
						cursor: pointer;
						float: right;
					}
				}
				.cartBox{
					border-top: 1px solid #EEEEEE;
					padding-top: 16px;
					-webkit-user-select:none;
			    -moz-user-select:none;
			    -ms-user-select:none;
			    user-select:none;
					.goodsTagBox{
						margin-bottom: 20px;
						.tagItem{
							cursor: pointer;
							padding: 0 10px 0 0;
							display: inline-block;
							border-radius: 2px;
							height: 44px;
							line-height: 44px;
							border: 1px solid #D9D9D9;
							position: relative;
							margin-right: 10px;
							.ciqImg{
								width: 36px;
								height: 36px;
								display: inline-block;
								float: left;
								margin: 4px;
							}
							.selectCiq{
								position: absolute;
								bottom: 0;
								right: 0;
							}
						}
						.active{
							border: 1px solid #E41135;
						}
					}
					.numControl{
						vertical-align: middle;
						display: inline-block;
						height: 30px;
						.btn{
							border: 1px solid #D9D9D9;
							font-size: 20px;
							font-weight: bold;
							color: #777;
							width: 30px;
							height: 30px;
							text-align: center;
							cursor: pointer;
							line-height: 26px;
						}
						.disabled{
							color: #ccc;
						}
						.num{
							border-top: 1px solid #D9D9D9;
							border-bottom: 1px solid #D9D9D9;
							height: 30px;
							padding-left: 5px;
							width: 60px;
						}
						div,input{
							color: #333;
							float: left;
							text-align: center;
						}
					}
					.limit{
						margin-top: 10px;
						color: #E41135;
						font-size: 12px;
						margin-left: 53px;
					}
				}
				.buyBox{
					margin: 38px 0;
					-webkit-user-select:none;
			    -moz-user-select:none;
			    -ms-user-select:none;
			    user-select:none;
					.item{
						vertical-align: middle;
						cursor: pointer;
						display: inline-block;
						margin-right: 10px;
						height: 50px;
						line-height: 50px;
						text-align: center;
						border-radius: 2px;
					}
					.buyNow{
						border: 1px solid #E41135;
						color: #E41135;
						font-size: 18px;
						background: #FCF7F8;
						width: 168px;
					}
					.buyCart{
						color: #fff;
						font-size: 18px;
						background: #E41135;
						width: 168px;
						img{
							vertical-align: middle;
						}
					}
					.disable{
						color: #333;
						background: #CCCCCC;
						border: none;
					}
					.kaiqiangTip{
						color: #fff;
						font-size: 18px;
						background: #FF6C00;
						width: 168px;
						img{
							vertical-align: middle;
						}
					}
					.active{
						color: #333;
						background: #CCCCCC;
					}
					.collect{
						img{
							margin-top: 8px;
						}
						line-height: 16px;
						width: 50px;
						border: 1px solid #D9D9D9;
						color: #999999;
						font-size: 12px;
					}
				}
			}
		}
		.itemTitle{
			height: 50px;
			line-height: 50px;
			padding-left: 24px;
			color: #333;
			font-size: 18px;
			background: #F2F2F2;
		}
		.recormendBox{
			border: 1px solid #D9D9D9;
			margin-bottom: 41px;
		}
		.pageFoot{
			.lookBox{
				display: inline-block;
				width: 199px;
				border: 1px solid #D9D9D9;
				margin-right: 10px;
				float: left;
			}
			.detailContent{
				width: 981px;
				display: inline-block;
				border: 1px solid #D9D9D9;
				.detail{
					font-size: 12px;
					color: #666;
					.detailcontent{
						margin: 0 44px;
						border-bottom: 1px solid #D9D9D9;
						line-height: 21px;
						.detailItem{
							width: 33%;
							display: inline-block;
							vertical-align: top;
							margin-bottom: 15px;
						}
						.brand{
							margin-top: 30px;
							margin-bottom: 15px;
						}
						.store{
							margin-bottom: 15px;
						}
					}
					.imgs{
						margin-top: 20px;
						text-align: center;
						img{
							display: inline-block;
						}
					}
				}
				.goodsComment{
					.commentsRate{
						margin: 0 44px;
						line-height: 90px;
						color: #666666;
						font-size: 12px;
						border-bottom: 1px solid #D9D9D9;
						.ant-statistic{
							font-size: 23px;
							display: inline-block;
							.ant-statistic-content{
								margin: 0 20px;
								color: #E41135;
								font-weight: bold;
							}
						}
					}
					.commentBox{
						.commentItem{
							margin: 0 44px;
							padding: 30px 0;
							border-bottom: 1px solid #D9D9D9;
							.headerImg{
								display: inline-block;
								vertical-align: top;
								width: 40px;
								height: 40px;
								border-radius: 20px;
								border: 1px solid #BABABA;
								overflow: hidden;
								img{
									width: 100%;
									height: 100%;
								}
							}
							.middle{
								padding: 0 30px;
								width: 770px;
								display: inline-block;
								vertical-align: top;
								.name{
									font-size: 12px;
									color: #999999;
								}
								.commentText{
									font-size: 14px;
									color: #333;
									margin-top: 5px;
								}
								.commentImgBox{
									margin-top: 10px;
									.commentImgItem{
										display: inline-block;
										width: 60px;
										height: 60px;
										border: 1px solid #D9D9D9;
										margin-right: 12px;
									}
								}
								.ant-rate{
									line-height: 20px;
								}
							}
							.time{
								font-size: 12px;
								color: #999999;
								display: inline-block;
								vertical-align: top;
							}
						}
					}
					.noComments{
						text-align: center;
						height: 200px;
						line-height: 200px;
						img{
							margin-right: 5px;
							vertical-align: middle;
						}
					}
				}
			}
		}
	}
}