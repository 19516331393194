.cosums_bg{
  height: 950px;
  margin: 0px;
  width: 100%;
  position: relative;
  background-color: #f2f2f2;
  .img_bg{
    position: absolute;
    left: 50%;
    margin-left: -604px;
    top: 43px;
    width: 1208px;
    height: 840px;
    // background-color: white;
    .img_st{
      position: absolute;
      margin: 0px;
      height: 840px;
      width: 1208px;
    }
    .title{
      position: absolute;
      color: #333333;
      font-size: 24px;
      font-weight: 100px;
      text-align: center;
      top: 236px;
      width: 1208px;
    }
    .content{
      position: absolute;
      color: #333333;
      font-size: 16px;
      top: 297px;
      left: 214px;
      right: 214px;
      background-color: white;
    }
  }
  
}