.ModelGoods{
	background: #F2F2F2;
	padding-bottom: 80px;
	.titleBox{
		height: 70px;
		line-height: 70px;
		background: url(../../images/bg_lingquanzhongxin.png);
		color: #fff;
		text-align: center;
		font-size: 24px;
		img{
			margin-top: -6px;
		}
	}
	.topImg{
		width: 100%;
		margin-bottom: 40px;
	}
	.inner{
		.ModelGoodsItem{
			float: left;
			margin-bottom: 10px;
		}
		.marR{
			margin-right: 10px;
		}
		.page{
			height: 70px;
			background: #fff;
			padding-top: 13px;
		}
		.limitBuyRoundBox{
			color: #fff;
			text-align: center;
			margin-bottom: 9px;
			.roundItem{
				padding-top: 8px;
				height: 56px;
				background: #333333;
				display: inline-block;
				.roundTime{
					line-height: 24px;
					font-size: 20px;
				}
				.roundStatus{
					font-size: 12px;
				}
				.sanjiao{
					margin-top: -3px;
				}
			}
		}
		.h5Content{
			width: 100%;
			height: 1000px;
		}
	}
}