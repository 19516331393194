.my_reabate_value_bg{
  width: 990px;
  .top_title{
    color: #333333;
    font-size: 18px;
    font-weight: bold;
    margin: 30px 50px 20px 50px;
  }
  .top_img_bg{
    margin: 0px 50px 20px 50px;
    height: 269px;
    position: relative;
    .top_img{
      position:absolute;
    }
    .rule_tip{
      color: #333333;
      font-size: 12px;
      background-color: white;
    }
    .top_rule_bg{
      position: absolute;
      top: 46px;
      right: 30px;
      clear: both;
      .top_rule_img{
        float: right;
        margin-top: 1px;
      }
      .top_rule_title{
        float: right;
        margin-right: 4px;
        color: white;
        font-size: 14px;
      }
    }
    .surplus_rebate_bg{
      top: 76px;
      left: 0px;
      right: 0px;
      position: absolute;
      display: flex;
      justify-content: center;
      color: white;
      font-weight: bold;
      .value_tag{
        font-size: 24px;
        margin-top: 30px;
      }
      .rebate_value{
        font-size: 50px;
      }
    }
    .tip_desc{
      top: 151px;
      left: 0px;
      right: 0px;
      color: white;
      position: absolute;
      text-align: center;
    }
  }
  .rebate_list_bg{
    margin: 0px 50px 50px 50px;
    height: 533px;
    border: 1px #d9d9d9 solid;
    .list_select_bg{
      width: 890px;
      height: 52px;
      background-color: #f2f2f2;
      border: 1px #d9d9d9 solid;
      margin-left: -1px;
      margin-top: -1px;
      clear: both;
      text-align: center;
      line-height: 51px;
      .select_item_nomal_bg{
        width: 143px;
        height: 52px;
        float: left;
        border: 1px #d9d9d9 solid;
        margin-top: -1px;
        color: #333333;
        .select_rec_line{
          width: 143px;
          height: 4px;
        }
      }
      .select_item_select_bg{
        width: 143px;
        height: 51px;
        float: left;
        background-color: white;
        color: #e41135;
        .select_rec_line{
          width: 143px;
          height: 4px;
          background-color: #e41135;
        }
      }
    }
    .no_data{
      text-align: center;
      margin: 0px;
      line-height: 300px;
      color: #333333;
      font-size: 14px;
    }
    .rebate_list_content_bg{
      margin: 0px;
      height: 481px;
      overflow: scroll;
      overflow-x: hidden;
      .rebate_item_bg{
        height: 60px;
        margin: 0px;
        .info_bg{
          height: 59px;
          margin: 0px;
          clear: both;
          .time{
            height: 59px;
            width: 278px;
            line-height: 59px;
            margin-left: 60px;
            font-size: 12px;
            color: #666666;
            float: left;
          }
          .rebate_value{
            height: 59px;
            width: 247px;
            line-height: 59px;
            font-size: 18px;
            font-weight: bold;
            color: #e41135;
            float: left;
          }
          .rebate_desc{
            height: 59px;
            line-height: 59px;
            font-size: 12px;
            color: #333333;
            float: left;
          }
        }
        .item_spe_line{
          margin: 0px 45px 0px 45px;
          height: 1px;
          background-color: #d9d9d9;
        }
      }
    }
  }
}