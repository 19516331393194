.SubmitOrder{
	position: relative;
	.realName{
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.3);
		position: fixed;
		top: 0;
		z-index: 100;
		.realNameBox{
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background: #fff;
			width: 500px;
			height: 416px;
			margin: auto;
			.realnameTitle{
				color: #333333;
				font-size: 14px;
				height: 40px;
				line-height: 40px;
				padding-left: 10px;
				border: 1px solid #E5E5E5;
				img{
					cursor: pointer;
					float: right;
				}
			}
			.inputBox{
				margin-top: 26px;
				margin-left: 30px;
				.inputTitle{
					display: inline-block;
					width: 120px;
					text-align: right;
				}
				input{
					width: 308px;
					border: 1px solid #D9D9D9;
					height: 32px;
					padding-left: 5px;
				}
			}
			.tip{
				margin-top: 10px;
				margin-left: 150px;
				color: #E41135;
				font-size: 14px;
			}
			.haiguanTip{
				margin-left: 32px;
				width: 436px;
				margin-top: 40px;
				padding: 10px;
				background: #FFFBE6;
				border: 1px solid #FAAD14;
				font-size: 12px;
			}
			.btn{
				margin-top: 30px;
				display: inline-block;
				font-size: 14px;
				cursor: pointer;
				width: 88px;
				height: 32px;
				line-height: 32px;
				text-align: center;
				border-radius: 4px;
			}
			.sure{
				margin: 0 24px 0 150px;
				background: #E41135;
				color: #fff;
			}
			.cancle{
				border: 1px solid #D9D9D9;
			}
		}
	}
	.inner{
		.orderTitle{
			font-size: 18px;
			color: #333;
			font-weight: bold;
			line-height: 60px;
			border-bottom: 1px solid #D9D9D9;
		}
		.orderInfo{
			padding: 0px 0px 40px;
			.itemTitle{
				padding: 0 30px;
				font-size: 16px;
				font-weight: bold;
				color: #333;
				line-height: 60px;
				.addNewAdress{
					margin-left: 32px;
					display: inline-block;
					height: 30px;
					line-height: 30px;
					font-size: 14px;
					padding: 0 6px;
					color: #666666;
					cursor: pointer;
					border: 1px solid #666666;
				}
			}
			.addressBox{
				padding: 0 30px;
				padding-bottom: 20px;
				max-height: 381px;
				overflow: auto;
				border-bottom: 1px solid #E5E5E5;
				.addressItem{
					vertical-align: top;
					cursor: pointer;
					margin-right: 20px;
					margin-bottom: 20px;
					display: inline-block;
					padding: 0 20px;
					width: 350px;
					height: 160px;
					border: 1px solid #D9D9D9;
					position: relative;
					border-radius: 6px;
					.nameBox{
						padding-top: 10px;
						height: 50px;
						line-height: 40px;
						border-bottom: 1px solid #E5E5E5;
						.name{
							color: #333333;
							font-size: 14px;
							font-weight: bold;
							display: inline-block;
						}
						.addressDefault{
							color: #666666;
							font-size: 12px;
							display: inline-block;
							float: right;
						}
					}
					.phone{
						color: #333;
						font-size: 14px;
						line-height: 26px;
						padding-top: 6px;
					}
					.addressSel{
						position: absolute;
						bottom: -1px;
						right: -1px;
					}
					.addressBtn{
						position: absolute;
						bottom: 6px;
						.btn{
							display: inline-block;
							margin-right: 15px;
							cursor: pointer;
						}
					}
				}
				.active{
					border: 1px solid #E41135;
				}
			}
			.tableTitleBox{
				color: #666666;
				font-size: 12px;
				margin-bottom: 10px;
				.tableTitleItem{
					display: inline-block;
				}
				.title{
					padding-left: 50px;
					width: 687px;
				}
				.num{
					text-align: center;
					width: 270px;
				}
				.price{
					text-align: center;
					width: 190px;
				}
			}
			.goodsTable{
				padding: 0 30px 30px;
				border-bottom: 1px solid #E5E5E5;
				.cangkuItem{
					margin-top: -1px;
					border: 1px solid #D9D9D9;
				}
				.cangkuName{
					color: #666;
					height: 32px;
					line-height: 30px;
					padding-left: 20px;
					background: #F2F2F2;
				}
				.error{
					color: #E41135;
				}
				.cangkuProItem{
					padding: 10px 0;
					margin: -1px 10px 0;
					border-top: 1px solid #E5E5E5;
					.proImg{
						vertical-align: top;
						display: inline-block;
						width: 68px;
						height: 68px;
						border: 1px solid #EEEEEE;
					}
					.titleBox{
						vertical-align: top;
						margin-left: 20px;
						display: inline-block;
						width: 560px;
						.title{
							height: 44px;
						}
						.activity{
							display: inline-block;
							padding: 2px 5px;
							color: #fff;
							border-radius: 2px;
							background: #E41135;
							font-size: 12px;
						}
					}
					.num{
						vertical-align: top;
						display: inline-block;
						text-align: center;
						width: 270px;
					}
					.price{
						vertical-align: top;
						display: inline-block;
						text-align: center;
						width: 190px;
					}
				}
			}
		}
		.saveBox{
			margin:0 30px;
			border: 1px solid #D9D9D9;
			.tabBox{
				background: #F2F2F2;
			}
			.saveCoupon{
				padding: 30px;
				max-height: 310px;
				width: 100%;
				background: #fff;
				overflow: auto;
 				.couponItem{
 					position: relative;
 					cursor: pointer;
					width: 200px;
					height: 103px;
					margin-right: 42px;
					margin-bottom: 39px;
					display: inline-block;
					.priceBox{
						position: relative;
						z-index: 1;
						padding-top: 8px;
						text-align: center;
						color: #fff;
						.simbol{
							margin-top: 6px;
							display: inline-block;
							vertical-align: middle;
						}
						.money{
							display: inline-block;
							vertical-align: middle;
							font-size: 24px;
							font-weight: bold;
						}
						.condition{
							display: inline-block;
							vertical-align: middle;
							margin-left: 9px;
						}
					}
					.time{
						position: relative;
						z-index: 1;
						color: #fff;
						text-align: center;
						font-size: 12px;
					}
					.range{
						position: relative;
						z-index: 1;
						margin-top: 14px;
						text-align: center;
					}
					.bg_img{
						position: absolute;
						top: 0;
						z-index: 0;
					}
					.addressSel{
						width: 20px;
						position: absolute;
						bottom: 0px;
						right: 0px;
					}
				}
			}
			.saveLackMoney{
				padding: 30px;
				width: 100%;
				background: #fff;
				.useLackMoney{
 					cursor: pointer;
					position: relative;
					display: inline-block;
					margin-left: 20px;
					width: 125px;
					height: 30px;
					line-height: 28px;
					text-align: center;
					border: 1px solid #D9D9D9;
					.selLackMoney{
						position: absolute;
						bottom: -1px;
						right: -1px;
					}
				}
				.active{
					border: 1px solid #E41135;
				}
			}
			.saveDiscount{
				padding: 30px;
				width: 100%;
				background: #fff;
				.discountCardItem{
 					cursor: pointer;
					position: relative;
					text-align: center;
					color: #fff;
					background: url(../../images/bg_card.png);
					width: 200px;
					height: 100px;
					.discountPrice{
						padding-top: 10px;
						.discountRate{
							margin-left: 3px;
							font-size: 24px;
							display: inline-block;
						}
					}
					.discountTime{
						margin-bottom: 3px;
					}
					.addressSel{
						width: 20px;
						position: absolute;
						bottom: 0px;
						right: 0px;
					}
				}
			}
		}
		.jiesuanBox{
			padding: 20px;
			margin: 30px;
			background: #F2F2F2;
			.jiesuanItem{
				line-height: 30px;
				.priceTitle{
					position: relative;
					text-align: right;
					display: inline-block;
					width: 85%;
					.icon{
						right: -20px;
						top: 8px;
						position: absolute;
						display: block;
				    width: 14px;
				    height: 14px;
				    border-radius: 50%;
				    border: 1px solid #007EDF;
				    text-align: center;
				    line-height: 14px;
				    color: #007EDF;
				    font-size: 10px;
				    font-family: Helvetica;
				    cursor: pointer;
					}
				}
				.price{
					width: 10%;
					display: inline-block;
					text-align: right;
				}
			}
			.heji{
				.priceTitle{
					font-size: 16px;
					color: #E41135;
				}
				.price{
					font-size: 18px;
					color: #E41135;
					font-weight: bold;
				}
			}
			.submit{
				margin: 30px 0px 20px 900px;
				margin-top: 30px;
				margin-left: 900px;
				width:135px;
				height:36px;
				text-align: center;
				line-height: 36px;
				background: #E41135;
				color: #fff;
				cursor: pointer;
				border-radius: 2px;
				font-size: 18px;
			}
			.adressOrder{
				line-height: 24px;
				width: 95%;
				text-align: right;
				.orderPhone{
					display: inline-block;
					margin-left: 20px;
				}
			}
		}
	}
}