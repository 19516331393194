.HotSellInfo{
  width: 100%;
  background-color: #F2F2F2;
  height: 464px;
  padding-bottom:60px;
  .inner{
    .recTitle{
      height: 61px;
      line-height: 61px;
      color: #333333;
      font-size: 22px;
      font-weight:bold;
      .theme{
        float: left;
        height: 61px;
        line-height: 61px;
      }
      .more{
        float: right;
        height: 61px;
        line-height: 61px;
        font-size: 15px;
        color: #999999;
        cursor: pointer;
        img{
          margin: 0 0 3px 5px;
        }
      }
    }
    .recClassify{
      width: 1190px;
      height: 343px;
      .limitProduct{
        width: 1190px;
        height: 343px;
        float: right;
        position: relative;
        .productShow{
          width: 1190px;
          height: 343px;
          overflow: hidden;
          .innerContent{
            margin-bottom: 0px;
            li{
              float: left;
              width: 390px;
              height: 343px;
              margin-right: 10px;
              .conimg{
                background-color:#fff;
                width: 390px;
                height: 222px;
                img{
                  width: 390px;
                  height: 222px;
                }
              }
              .conText{
                background-color: #fff;
                height: 121px;
                width: 390px;
                padding:18px 22px 2px 25px;
                .title{
                  font-size: 21px;
                  color: #333333;
                  line-height: 27px;
                }
                .text{
                  color: #666666;
                  font-size: 16px;
                }
              }
            }
          }
        }
        .arrow_l{
          position: absolute;
          left:10px;
          top:116px;
        }
        .arrow_r{
          position: absolute;
          right:10px;
          top:116px;
        }
        .hidden{
          display: none;
        }
      }
    }
  }
}