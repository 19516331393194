.my_collction_bg{
  margin: 0px;
  width: 990px;
  color: #333333;
  .my_collction_ttile{
    margin: 30px 50px 24px 50px;
    font-size: 18px;
    font-weight: bold;
  }
  .my_collection_list_bg{
    margin: 0px 35px 0px 50px;
    .section_bg{
      margin: 0px;
      clear: both;
      .section_title_bg{
        margin: 0px;
        height: 55px;
        color: #333333;
        position: relative;
        .today{
          font-weight: bold;
          font-size: 30px;
          position: absolute;
          left: 0;
          top: 0;
          padding-right: 10px;
          height: 45px;
          background-color: white;
        }
        .time_display_none{
          display: none;
        }
        .time{
          font-size: 20px;
          position: absolute;
          left: 70px;
          top: 15px;
          padding-right: 10px;
          height: 30px;
          background-color: white;
        }
        .spe_line{
          width: 880px;
          height: 1px;
          background-color: #d9d9d9;
          position: absolute;
          left: 10px;
          top: 30px;
        }
      }
      .collection_item_bg{
        width: 166px;
        height: 280px;
        margin: 0px 15px 15px 0px;
        border: 1px #d9d9d9 solid; 
        float: left;
        position: relative;
        .conten_mask{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.65);
          .mask_tip_bg{
            margin: 95px 0px 0px 0px;
            display: flex;
            justify-content: center;
            .tip_img{
              width: 14px;
              height: 14px;
              margin-right: 4px;
              margin-top: 2px;
            }
            .tip_info{
              font-size: 12px;
              color: white;
            }
          }
          .btns_bg{
            margin: 20px 0px 0px 0px;
            display: flex;
            justify-content: center;
            .sure_btn{
              width: 54px;
              line-height: 24px;
              text-align: center;
              background-color: #e41135;
              color: white;
              font-size: 12px;
              margin-right: 10px;
              border-radius: 4px;
            }
            .cancel_btn{
              width: 54px;
              line-height: 24px;
              text-align: center;
              background-color: white;
              color: #666666;
              font-size: 12px;
              border-radius: 4px;
            }
          }
        }
        .content_bg{
          position: absolute;
          left: 0;
          top: 0;
          .goods_img_bg{
            margin: 0px;
            position: relative;
            height: 136px;
            &:hover{
              div{
                display: block;
              }
            }
            .goods_img{
              width: 126px;
              height: 126px;
              left: 20px;
              top: 10px;
              position: absolute;
            }
            .add_shop_cart_bg{
              display: none;
              height: 24px;
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              clear: both;
              width: 164px;
              .add_shop_cart{
                width: 82px;
                line-height: 24px;
                background-color: #e41135;
                color: white;
                float: left;
                font-size: 12px;
                text-align: center;
              }
              .delete{
                width: 82px;
                line-height: 24px;
                background-color: rgba(0, 0, 0, 0.3);
                color: white;
                float: left;
                font-size: 12px;
                text-align: center;
              }
            }
          }
          .country_bg{
            margin: 4px 0px 0px 14px;
            clear: both;
            height: 18px;
            .country_img{
              width: 21px;
              height: 14px;
              margin-top: 2px;
              float: left;
            }
            .coutry_name{
              font-size: 12px;
              float: left;
              margin-left: 6px;
            }
          }
          .name{
            margin: 0px 14px 0px 14px;
            height: 36px;
            font-size: 12px;
            overflow: hidden;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            display:-webkit-box;
            -webkit-box-orient: vertical;
            height: 36px;
          }
          .tag_bg{
            margin-left:14px; 
            margin-top: 6px;
            height: 30px;
            overflow: hidden;
            .activity_tag{
              line-height: 24px;
              padding:3px;
              border: 1px #e41135 solid;
              border-radius: 4px;
              font-size: 12px;
              color: #e41135;
            }
          }
          .price_bg{
            margin:0px 14px 0px 14px;
            clear: both;
            height: 24px;
            .price{
              color: #e41135;
              font-size: 15px;
              font-weight: bold;
              float: left;
            }
            .dicount{
              margin: 2px 0px 0px 4px;
              height: 18px;
              background-color: #ffebee;
              color: #e41135;
              border-radius: 9px;
              padding: 0px 9px 0px 9px;
              font-size: 12px;
              float: left;
            }
          }
          .special_price{
            font-size: 12px;
            color: #ff6c00;
            margin:0px 14px 0px 14px;
          }
          .special_price_gray{
            font-size: 12px;
            color: #999999;
            margin:0px 14px 0px 14px;
            text-decoration: line-through;
          }
        }
        
      }
    }
  }
  .page_bg{
    margin: 0;
    height: 100px;
  }
}