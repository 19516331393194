.PayFinish{
	background: #fff;
	.inner{
		.payResult{
			border: 1px solid #D9D9D9;
			margin: 70px 0 20px;
			padding: 27px 50px;
			.payIcon{
				display: inline-block;
				vertical-align: top;
				margin-right: 20px;
			}
			.payContent{
				display: inline-block;
				vertical-align: top;
				width: 422px;
				margin-right: 225px;
				.payResultText{
					font-size: 18px;
					color: #333333;
					margin-bottom: 20px;
					font-weight: bold;
				}
				.bianhao{
					font-size: 14px;
					color: #333;
					line-height: 32px;
				}
				.btnBox{
					margin-top: 40px;
					.btn{
						cursor: pointer;
						margin-right: 20px;
						display: inline-block;
						line-height: 32px;
					}
					.goCart{
						width: 102px;
						text-align: center;
						color: #fff;
						background: #E41135;
						border-radius: 4px;
					}
					.underLine{
						color: #005AA0;
						font-size: 14px;
						text-decoration: underline;
					}
				}
				.tip{
					color: #999999;
					font-size: 12px;
					margin-top: 10px;
				}
			}
			.appCode{
				padding-top: 60px;
				width: 300px;
				text-align: center;
				display: inline-block;
				vertical-align: top;
				line-height: 28px;
			}
		}
		.recormendBox{
			border: 1px solid #D9D9D9;
			margin-bottom: 41px;
			.itemTitle{
				height: 50px;
				line-height: 50px;
				padding-left: 24px;
				color: #333;
				font-size: 18px;
				background: #F2F2F2;
			}
		}
	}
}
