.bottom_goods_bg{
  margin: 0px;
  position: relative;
  width: 1190px;
  .bottom_goods_title{
    font-weight: bold;
    font-size: 18px;
    position: absolute;
    top: 26px;
    left: 24px;
    color: #333333;
  }
  .item_list_bg{
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    height: 313px;
    clear: both;
    .item_bg{
      float: left;
      width: 197px;
      height: 313px;
      .goods_img{
        margin: 20px 25px 0px 24px;
        background-color: aqua;
        width: 148px;
        height: 148px;
      }
      .name{
        margin: 10px 20px 0px 20px;
        font-size: 12px;
      }
      .price_bg{
        margin:8px 20px 0px 20px;
        clear: both;
        height: 24px;
        .price{
          color: #e41135;
          font-size: 15px;
          font-weight: bold;
          float: left;
        }
        .dicount{
          margin: 2px 0px 0px 4px;
          height: 18px;
          background-color: #ffebee;
          color: #e41135;
          border-radius: 9px;
          padding: 0px 9px 0px 9px;
          font-size: 12px;
          float: left;
        }
      }
      .special_price{
        font-size: 12px;
        color: #ff6c00;
        margin:2px 20px 0px 20px;
      }
      .comment{
        font-size: 12px;
        color: #666666;
        margin:4px 20px 0px 20px;
      }
    }
  }
  .item_left_img{
    position: absolute;
    top: 184px;
    left: 0;
    width: 20px;
    height: 45px;
  }
  .item_right_img{
    position: absolute;
    top: 184px;
    right: 0;
    width: 20px;
    height: 45px;
  }
}