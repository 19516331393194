.address_manager_bg{
  width: 990px;
  margin: 0px 0px 50px 0px;
  .address_top_ttile{
    color: #333333;
    font-weight: bold;
    font-size: 18px;
    margin-top: 30px;
    margin-left: 50px;
  }
  .add_new_address_btn_bg{
    clear: both;
    height: 20px;
    .add_new_address_btn{
      margin-right: 78px;
      color: #e41135;
      font-size: 14px;
      float:right;
    }
  }
  .address_list_bg{
    margin-left: 50px;
    margin-top: 10px;
    width: 890px;
    border: 1px #d9d9d9 solid;
    color: #333333;
    .address_list_title_bg{
      margin: 0px;
      background-color: #f4f4f4;
      height: 44px;
      clear: both;
      .address_person_title{
        width: 150px;
        line-height: 44px;
        text-align: center;
        float: left;
      }
      .address_title{
        width: 305px;
        line-height: 44px;
        text-align: center;
        float: left;
      }
      .address_phone_num_title{
        width: 175px;
        line-height: 44px;
        text-align: center;
        float: left;
      }
      .address_opration_title{
        width: 140px;
        line-height: 44px;
        text-align: center;
        float: left;
      }
    }
    .item_top_line{
      margin: 0px;
      height: 1px;
      background-color: #d9d9d9;
    }
    .address_item_bg{
      height: 88px;
      margin: 0px;
      clear: both;
      display: flex;
      justify-items: flex-start;
      align-items: center;
      .address_person{
        width: 150px;
        line-height: 87px;
        text-align: center;
        float: left;
      }
      .address{
        width: 305px;
        float: left;
        .address_content{
          margin: 5px;
        }
      }
      .address_phone_num{
        width: 175px;
        line-height: 87px;
        text-align: center;
        float: left;
      }
      .address_opration{
        line-height: 87px;
        float: left;
        color: #005aa0;
        padding-left: 32px;
        .edit_btn{
          float: left;
          cursor: pointer;
        }
        .mid_line{
          width: 1px;
          height: 14px;
          margin-left: 8px;
          margin-right: 8px;
          margin-top: 38px;
          float: left;
          background-color: #005aa0;
        }
        .default_btn_true{
          width: 50px;
          margin-left: 50px;
          margin-top: 33px;
          float: left;
          line-height: 20px;
          text-align: center;
          color: white;
          background-color: #e41135;
          border-radius: 2px;
          cursor: pointer;
        }
        .default_btn_false{
          margin-left: 47px;
          float: left;
          cursor: pointer;
        }
      }
    }
  }
  
}