.Banner{
  width: 1920px;
  height: 470px;
  overflow: hidden;
  position: relative;
  left: 50%;
  margin-left: -960px;
  .carousel {
    position: relative;
    width: 100%;
    height: 470px;
    .carousel-image {
        width: 1920px;
        height: 470px;
        overflow: hidden;
        .carousel-image-item {
            position: relative;
            width: 1920px;
            height: 470px;
            img{
              width: 100%;
              height: 100%;
            }
            .carousel-image-item-enter {
                position: absolute;
                opacity: 0.01;
                &.carousel-image-item-enter-active {
                    opacity: 1;
                    transition: 1.5s opacity ease-in-out;
                }
            }
            // .carousel-image-item-leave {
            //     position: absolute;
            //     opacity: 1;
            //     &.carousel-image-item-leave-active {
            //         opacity: 0.01;
            //         transition: 2.0s opacity ease;
            //     }
            // }
        }
    }
    .carousel-nav {
      text-align: center;
        position: absolute;
        width: 300px;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 60px;
        .carousel-nav-item {
            list-style: none;
            display: inline-block;
            margin-right: 8px;
            width: 8px;
            height: 8px;
            background-color:rgba(00,00,00,.2);
            border-radius: 50%;
            cursor: pointer;
            &.carousel-nav-item-active {
              background-color: #e41135;
            }
        }
    }
    .button-group {
      display: none;
      span {
          position: absolute;
          width: 40px;
          font-size: 30px;
          text-align: center;
          line-height:60px; 
          color: #fff;
          top: 168px;
      }
      .button-left {
          left:561px;
          cursor:pointer;
      }
      .button-right {
          right: 371px;
          cursor:pointer;
      }
    }
    &:hover{
      .button-group{
        display:block; 
      }
    }
  }
  .contentWrap{
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    height: 50px;
    ul{
      width: 1190px;
      height: 50px;
      line-height: 50px;
      li{
        float: left;
        margin-right: 40px;
        span{
          color: #FFFFFFFF;
          font-size: 14px;
          margin-left: 4px;
        }
      }
    }
  }
}