.progressBar{
	padding-left: 10px;
	ul{
		top: 46px;
		position: relative;
		li{
			width: 146px;
			float: left;
			.imgBox{
				height: 40px;
				.sel{
					width: 34px;
					height: 34px;
					z-index: 1;
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto 0;
				}
				.line{
					background: #51b74c;
					height: 4px;
					width: 146px;
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto 0;
				}
				.longline{
					width: 443px;
				}
				.lineNor{
					background: #cdcdcd;
				}
			}
			.text{
				color: #333333;
				font-weight: bold;
				bottom: 29px;
				position: absolute;
				transform:translateX(-50%);
				-ms-transform:translateX(-50%); 	/* IE 9 */
				-moz-transform:translateX(-50%); 	/* Firefox */
				-webkit-transform:translateX(-50%); /* Safari 和 Chrome */
				-o-transform:translateX(-50%); 
			}
			.textNor{
				color: #999999;
			}
		}
	}
}