.InputItem{
	position: relative;
	border: 1px solid #D9D9D9;
	height: 38px;
	border-radius: 4px;
	margin: 14px auto;
	text-align: left;
	.icon{
		vertical-align: middle;
	}
	.input{
		vertical-align: middle;
		height: 36px;
		font-size: 14px;
		width: 60%;
	}
	.getCode{
		width: 100px;
		background: #f4f4f4;
		position: absolute;
		right: 0;
		top: 0;
		height: 36px;
		line-height: 36px;
		text-align: center;
		color: #333;
		font-size: 12px;
		cursor: pointer;
		border-radius: 0 4px 4px 0;
	}
	.ant-statistic-content{
		position: relative;
		height: 36px;
	}
	.ant-statistic-content-value{
		left: 0;
		width: 100%;
		position: absolute;
		top: 0;
		display: inline-block;
		color: #333;
		height: 36px;
		line-height: 36px;
		font-size: 12px;
	}
	.clear{
		cursor: pointer;
		margin-top: 2px;
		float: right;
		vertical-align: middle;
	}
}
.err{
	border: 1px solid #E41135;
}