.ResetPassword{
	.inner{
		text-align: center;
		.pageTitle{
			font-size: 28px;
			color: #333;
			height: 40px;
			line-height: 40px;
			margin: 47px 0 11px;
		}
		.resetLine{
			margin-left: 154px;
			display: inline-block;
			height: 140px;
		}
		.inputBox{
			height: 160px;
			.item{
				display: inline-block;
				width: 360px;
				position: relative;
				.errTip{
					position: absolute;
					height: 20px;
					left: 370px;
					top: 0;
					bottom: 0;
					margin: auto;
					font-size: 12px;
					color: #E41135;
					width: 500px;
					text-align: left;
					img{
						margin-right: 5px;
					}
				}
			}
		}
		.btn{
			width:360px;
			height:52px;
			background:#E41135;
			border-radius:4px;
			color: #fff;
			cursor: pointer;
			display: inline-block;
			line-height: 52px;
		}
	}
}