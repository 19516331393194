.noneList{
	text-align: center;
	height: 286px;
	background: #fff;
	padding: 42px 404px 48px 410px;
	.notice{
		text-align: left;
		height: 28px;
		line-height: 28px;
		margin-bottom: 16px;
		.tanhao{
			margin-right: 14px;
			vertical-align: middle;
		}
		.noticeText{
			display: inline-block;
			font-size: 14px;
			color: #333333;
			font-weight: Bold;
			span{
				color:#e41135;
			}
		}
	}
	.advise{
		margin-left: 42px;
		text-align: left;
		color: #666666;
		line-height: 20px;
		span{
			display: block;
		}
	}
	.backBtn{
		margin: 40px 0 0 140px;
		width:102px;
		height:32px;
		background:rgba(228,17,53,1);
		opacity:1;
		border-radius:4px;
		line-height: 32px;
		color: #FFFFFF;
		cursor: pointer;
	}
}