.limitBuy{
  width: 100%;
  height: 360px;
  padding:40px 0 40px 0;
  .inner{
    .countDown{
      width: 197px;
      height: 288px;
      float: left;
      background-color: #E41135;
      padding: 28px 30px 45px 30px;
      text-align: center;
      .bigTitle{
        color: #FFFEFEFE;
        font-size: 30px;
        margin-bottom: 20px;
      }
      .subtitle{
        color: #FFFEFEFE;
        font-size: 14px;
        margin-bottom: 20px;
      }
      .daojishiBox{
        display: inline-block;
        position: relative;
        height: 32px;
        margin-top: 5px;
        margin-bottom: 40px;
        .maohao{
          text-align: center;
          width: 10px;
          line-height: 30px;
          height: 32px;
          font-size: 24px;
          height: 100%;
          background: #E41135;
          position: absolute;
        }
        .l{
          top: 0;
          right: 31px;
        }
        .r{
          top: 0;
          right: 74px;
        }
        .ant-statistic-content{
          line-height: 32px;
          font-size:18px;
          font-family:Helvetica;
        }
      }
      .clickAll{
        width: 124px;
        height: 30px;
        line-height: 30px;
        background-color: #fff;
        border-radius:15px;
        margin-left: 7px;
        a{
         display: block;
         width: 100%;
         height: 100%;
         color: #E41135;
         font-size: 14px;
        }
      }
    }
    .limitProduct{
      width: 993px;
      height: 288px;
      float: right;
      position: relative;
      .productShow{
        width: 992px;
        height: 288px;
        overflow: hidden;
        background: #fff;
        .InnerContent{
          margin-bottom: 0px;
          li{
            float: left;
            width: 196.5px;
            height: 288px;
            margin-right: 2px;
          }
        }
      }
      .arrow_l{
        position: absolute;
        left:0;
        top:102px;
        cursor: pointer;
        border-radius: 0 22.5px 22.5px 0;
      }
      .arrow_l:hover{
        background: rgba(00,00,00,.2);
      }
      .arrow_r{
        border-radius: 22.5px 0 0 22.5px;
        position: absolute;
        top:102px;
        cursor: pointer;
        right: 0;
      }
      .arrow_r:hover{
        background: rgba(00,00,00,.2);
      }
      .hidden{
        display: none;
      }
    }
  }
}