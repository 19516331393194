.timeLine{
	padding-left: 10px;
	ul{
		top: 46px;
		position: relative;
		li{
			width: 180px;
			float: left;
			.imgBox{
				height: 40px;
				.sel{
					width: 18px;
					height: 18px;
					z-index: 1;
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto 0;
				}
				.line{
					background: #51b74c;
					height: 1px;
					width: 180px;
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto 0;
				}
				.lineNor{
					background: #cdcdcd;
				}
			}
			.text{
				color: #E41135;
				bottom: -47px;
				position: absolute;
				transform:translateX(-50%);
				-ms-transform:translateX(-50%); 	/* IE 9 */
				-moz-transform:translateX(-50%); 	/* Firefox */
				-webkit-transform:translateX(-50%); /* Safari 和 Chrome */
				-o-transform:translateX(-50%); 
			}
			.textNor{
				color: #999999;
			}
		}
	}
}