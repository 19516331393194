.blackBg{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.65);
  z-index: 2;
  .userRuleBox{
    width: 790px;
    height: 584px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    margin: auto;
    text-align: center;
    .userRuleTitle{
      height: 80px;
      line-height: 80px;
      color: #333;
      font-size: 18px;
      font-weight: bold;
    }
    .userRuleContent{
      padding: 0 54px;
      height: 412px;
      font-size: 14px;
      color: #333;
      overflow-y: scroll;
      text-align: left;
      ul.gy_list {
        font-size: 11px;
        list-style-type: none;
        margin-left: 0px;
        padding-left: 0px;
    }

    ul.gy_list>li {
        text-indent: 22px;
        padding-top: 4px;
    }

    div.gy_header {
        font-size: 11px;
        font-weight: bold;
        text-indent: 0px;
    }

    div.gy_content {
        margin-top: 10px;
        font-size: 11px;
        text-indent: 22px;
        margin-bottom: 10px;
    }

    body {
        margin: 0px 0px;
        padding: 0px 0px;
    }

    div.container {
        margin: 0px 8px;
        font-size: 11px;
    }
    }
    .userRuleKnow{
      width: 158px;
      margin: 25px auto 25px;
      color: #fff;
      height: 40px;
      background: #E41135;
      border-radius: 4px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }
  }
}