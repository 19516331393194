.add_new_address_pop_bg{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  .white_bg{
    width: 500px;
    height: 448px;
    background-color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -224px;
    margin-left: -250px;
    color: #333333;
    .add_address_title_bg{
      margin: 0x;
      height: 40px;
      clear: both;
      .add_address_title{
        margin-left: 10px;
        font-size: 14px;
        line-height: 40px;
        float: left;
      }
      .close{
        width: 20px;
        height: 20px;
        float: right;
        margin-right: 5px;
        margin-top: 8px;
      }
    }
    .top_line{
      margin: 0px;
      height: 1px;
      background-color: #e5e5e5;
    }
    .info_bg{
      margin-top:20px;  
      position: relative;
      height: 32px;
      .left_star{
        top: 5px;
        position:absolute;
        left: 38px;
        color: #e41135;
      }
      .left_title{
        left: 44px;
        top: 5px;
        width: 76px;
        font-size: 14px;
        text-align: left;
        position: absolute;
      }
      .user_name{
        width: 308px;
        height: 32px;
        border: 1px #d9d9d9 solid;
        position: absolute;
        left: 124px;
        font-size: 14px;
        line-height: 30px;
        padding-left: 10px;
      }
      .select_bg{
        position: absolute;
        left: 124px;
        height: 32px;
        .select{
          width: 96px;
          height: 32px;
          margin-right: 10px;
        }
      }
    }
    .address_detail_bg{
      margin-top:20px;  
      position: relative;
      height: 70px;
      .left_star{
        top: 5px;
        position:absolute;
        left: 38px;
        color: #e41135;
      }
      .left_title{
        left: 44px;
        top: 5px;
        width: 76px;
        font-size: 14px;
        text-align: left;
        position: absolute;
      }
      .address_input_bg{
        position: absolute;
        left: 124px;
        width: 308px;
        height: 70px;
        border: 1px #d9d9d9 solid;
      }
      .address_textarea{
        margin: 5px;
        width: 298px;
        height: 60px;
        font-size: 14px;
      }
    }
    .set_default_bg{
      margin-top:10px;
      margin-left: 124px;
      clear: both;
      background-color: aqua;
      .select_defalt_bg{
        margin-top: 2px;
        width: 18px;
        height: 18px;
        border: 1px #bababa solid;
        float: left;
        .select_defalt{
           margin: -3px 3px 4px 3px;
          width: 12px;
          height: 10px;
        }
      }  
      .set_defalt_lab{
        color: #333333;
        font-size: 14px;
        margin-left: 10px;
        float: left;
      }
    }
    .bottom_btn_bg{
      display: flex;
      justify-content: center;
      margin-top:50px;
      .red_btn{
        width: 88px;
        line-height: 32px;
        border-radius: 4px;
        background-color: #e41135;
        margin-top: 5px;
        text-align: center;
        color: white;
        margin-right: 20px;
        cursor: pointer;
        &:hover{
          opacity: 0.8;
        }
      }
      .balck_btn{
        width: 88px;
        line-height: 32px;
        border-radius: 4px;
        border: 1px #d9d9d9 solid;
        margin-top: 5px;
        text-align: center;
        color: #666666;
        cursor: pointer;
        &:hover{
          opacity: 0.8;
        }
      }
    }
  }
}