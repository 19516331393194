.returnInfoBox{
	display: none;
	width: 100%;
	height: 100%;
	z-index: 1000000;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	font-size: 14px;
	.sureAndCancelInner{
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
  	width: 500px;
	  height: 381px;
	  border-radius: 2px;
	  background-color: #ffffff;
	  box-shadow:0px 0px 18px rgba(0,0,0,0.25);
	  .title1{
	  	width: 100%;
	  	height: 40px;
	  	line-height: 40px;
	  	position: relative;
	  	border-bottom: 1px solid #E5E5E5;
	  	.boxName{
	  		margin-left: 14px;
	  		vertical-align: middle;
	  	}
	  	.closeBtn{
	  		cursor: pointer;
	  		position: absolute;
	  		top: 0;
	  		bottom: 0;
	  		right: 14px;
        margin: auto;
	  	}
	  }
	  .section{
	  	position: absolute;
	  	bottom: 0;
	  	height: 340px;
			width: 100%;
			padding-top: 20px;
			div{
				input{
					width: 308px;
					height: 32px;
					border:1px solid #D9D9D9;
					padding-left: 10px;
				}
				.title{
					display: inline-block;
					width: 114px;
					line-height: 24px;
					border-bottom: none;
					text-align: right;
					i{
						color: #E41135;
					}
				}
				.notice{
					line-height: 18px;
					display: block;
					font-size: 12px;
					color: #E41135;
					margin: 1px 0 1px 118px;
					.remove{
						display: inline-block;
						width: 16px;
						height: 16px;
						border-radius: 16px;
						background-color:#E41135;
						padding: 0 2px;
						vertical-align: bottom;
						margin-right: 5px;
						cursor: pointer;
						i{
							margin: 7px 0 0 1px;
							display: block;
							width: 10px;
							height: 2px;
							background-color: #fff;
						}
					}
				}
			}
			.input{
				margin-bottom: 20px;
			}
			.opBtn{
				width: 100%;
				position: absolute;
        bottom: 50px;
        left:0px;
				text-align: center;
				.cancel{
					margin: 0 10px;
          cursor: pointer;
          padding: 6px 0 0 0;
					text-align: center;
					display: inline-block;
					width: 88px;
				  height: 32px;
				  border-radius: 4px;
				  background-color: #fff;
				  border: solid 1px #D9D9D9;
          line-height: 20px;
          color: #666666;
				}
				.sure{
					margin: 0 10px;
          cursor: pointer;
          padding: 6px 0 0 0;
					line-height: 20px;
					text-align: center;
					color: #fff;
					display: inline-block;
					width: 88px;
				  height: 32px;
				  border-radius: 4px;
				  background-color: #E41135;
          border:1px solid rgba(228,17,53,1);
				}
			}
	  }
	}
}