.pop_back{
  width: 100%;
  height: 100%;
  z-index: 1001;
  position: fixed;
  background-color:rgba(0, 0, 0,0.5);
  top: 0;
  left: 0;
  .content_back{
		position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);
    -ms-transform:translate(-50%,-50%);   /* IE 9 */
    -moz-transform:translate(-50%,-50%);  /* Firefox */
    -webkit-transform:translate(-50%,-50%); /* Safari 和 Chrome */
    -o-transform:translate(-50%,-50%);  /* Opera */
    .coupon_img_back{
      position: relative;
  		.coupon_img{
      }
      .get_coupon_back{
        position: absolute;
        left: 50%;
        bottom: 0;
        transform:translate(-50%,0);
        -ms-transform:translate(-50%,0);   /* IE 9 */
        -moz-transform:translate(-50%,0);  /* Firefox */
        -webkit-transform:translate(-50%,0); /* Safari 和 Chrome */
        -o-transform:translate(-50%,0);  /* Opera */
        .get_coupon{
          width: 204px;
          height: 40px;
          line-height: 40px;
          border-radius: 20px;
          border: 1px solid #e41135;
          text-align: center;
  				color: #e41135;
          margin: 12px 0; 
          cursor: pointer;
        }
      }
    }
    .close_btn{
      cursor: pointer;
      position: absolute;
      width: 26px;
      height: 26px;
      top: 0;
      right: -43px;
    }
  }
}