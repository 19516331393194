.ProductItem{
  position: relative;
  width: 290px;
  height: 436px;
  padding: 20px;
  background-color: #fff;
  cursor: pointer;
  .tag{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
  }
  .sortTagBox{
    font-size: 18px;
    font-weight: 400;
    width: 26px;
    height: 26px;
    color: #fff;
    line-height: 26px;
    text-align: center;
    position: absolute;
    background-image: url(../../images/bg_tag_short.png);
    top: 10px;
    left: 10px;
    z-index: 5;
  }
  .Img{
    position: relative;
    width: 250px;
    height: 250px;
    div{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .labelPrice{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 65px;
        height: 21px;
        display: block;
        .ant-statistic{
          font-size:16px;
          font-weight: bold;
          text-align: center;
          line-height: 8px;
          .ant-statistic-content-prefix{
            display: inline-block;
            color: #FFF;
            font-size: 12px;
            line-height: 16px;
            font-weight: normal;
            vertical-align:bottom;
            margin-right: 0;
          }
          .ant-statistic-content-value{
            height: 16px;
            .ant-statistic-content-value-int{
              color: #FFFFFF;
              font-size: 16px;
            }
            .ant-statistic-content-value-decimal{
              font-size: 12px;
              color: #FFF;
              font-weight: normal;
            }
          }
        }
      }
      img{
        width: 100%;
        height: 100%;
      }
    }
    .proImg{
      width: 100%;
      height: 100%;
    }
    .stockNone{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
  .proContent{
    .country{
      font-size: 12px;
      color: #333;
      img{
        width: 24px;
        height: 18px;
        border:1px solid #D9D9D9;
        margin-right: 8px;
      }
    }
    .Title{
      font-size: 16px;
      color:#333333;
      line-height: 25px;
      height: 50px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .price{
      height: 36px;
      color: #E41135;
      .symbol{
        vertical-align: middle;
        font-size: 14px;
      }
      .number{
        vertical-align: middle;
        display: inline-block;
        height: 36px;
        line-height: 36px;
        font-size: 24px;
      }
      .discount{
        vertical-align: middle;
        padding: 0 8px;
        display: inline-block;
        line-height: 18px;
        height: 18px;
        border-radius:9px;
        background-color: #FFEBEE;
        font-size: 12px;
        margin-left: 5px;
        color: #E41135;
      }
      .oriPrice{
        display: inline-block;
        height: 18px;
        line-height: 18px;
        margin-top: 9px;
        color: #999999;
        font-size: 12px;
        float: right;
      }
      .lineThrough{
        text-decoration: line-through;
      }
    }
    .promotionBox{
      margin-top: 5px;
      height: 24px;
      overflow: hidden;
      .promotionItem{
        margin-right: 5px;
        padding: 0 9px;
        border-radius: 4px;
        border: 1px solid #E41135;
        display: inline-block;
        font-size: 12px;
        height: 24px;
        line-height: 22px;
      }
    }
    .saleComment{
      margin-top: 5px;
      color: #666;
      font-size: 12px;
      .comment{
        float: right;
      }
    }
  }
}