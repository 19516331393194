.LoginThirdBind{
	.inner{
		text-align: center;
		.pageTitle{
			font-size: 28px;
			color: #333;
			height: 40px;
			line-height: 40px;
			margin: 47px 0 11px;
		}
		.pageSubTitle{
			font-size: 14px;
			color: #666;
		}
		.loginTitle{
			margin-top: 38px;
			margin-bottom: 27px;
			.titleName{
				cursor: pointer;
				display: inline-block;
				width: 174px;
				text-align: center;
				padding-bottom: 10px;
			}
			.middleLine{
				vertical-align: middle;
				display: inline-block;
				width: 1px;
				height: 17px;
				background: #e5e5e5;
			}
			.borderR{
				border-right: 1px solid #E5E5E5;
			}
			.active{
				color: #E41135;
				border-bottom: 1px solid #E41135;
			}
		}
		.resetLine{
			margin-left: 154px;
			display: inline-block;
			height: 140px;
		}
		.inputBox{
			.item{
				display: inline-block;
				width: 360px;
				position: relative;
				.errTip{
					position: absolute;
					height: 20px;
					left: 370px;
					top: 0;
					bottom: 0;
					margin: auto;
					font-size: 12px;
					color: #E41135;
					width: 500px;
					text-align: left;
					img{
						margin-right: 5px;
					}
				}
			}
		}
		.btn{
			margin-top: 58px;
			width:360px;
			height:52px;
			background:#E41135;
			border-radius:4px;
			color: #fff;
			cursor: pointer;
			display: inline-block;
			line-height: 52px;
		}
		.userRule{
			margin-top: 20px;
			width: 360px;
			margin: 10px auto;
			font-size: 12px;
			color: #666;
			text-align: left;
			img{
				margin-right: 5px;
			}
			.rule{
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}
}