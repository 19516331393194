.searchPage{
  background-color: #F2F2F2;
  padding-bottom:80px; 
  .Sidebar{
    top:50px !important;
  }
  .SearchCondition{
    position: relative;
    width: 100%;
    font-size: 12px;
    .choseCondition{
      width: 100%;
      height: 50px;
      color: #333333;
      .fixedText{
        display: inline-block;
        height:26px;
        line-height: 26px;
        margin-top: 13px;
      }
      .partition{
        display: inline-block;
        zoom: 1;
        width: 10px;
        height: 26px;
        margin: 0 4px 0 6px;
        line-height: 26px;
        font-family: simsun;
        color: #999;
        font-weight: 700;
      }
      .SelectBox{
        position: relative;
        height: 26px;
        display: inline-block;
        margin-top: 13px;
        vertical-align: bottom;
        .content{
          display: none;
          max-width: 287px;
          background:rgba(255,255,252,1);
          border:1px solid rgba(186,186,186,1);
          box-shadow:0px 1px 3px rgba(0,0,0,0.3);
          position: absolute;
          top:37px;
          left: 0;
          z-index: 1000;
          padding: 0 5px;
          line-height: 20px;
        }
        .value{
          position: relative;
          display: inline-block;
          font-size: 12px;
          line-height: 16px;
          max-width: 160px;
          height: 26px;
          color: #666666;
          border:1px solid #BABABA;
          padding: 4px 24px 4px 8px;
          white-space: nowrap;
          overflow: hidden;
          background-color: #fff;
          vertical-align: bottom;
          cursor: pointer;
          i{
            color: #E41135;
          }
          .icon{
            position: absolute;
            top: 0;
            right: 0;
            width: 24px;
            height: 24px;
            font-size: 10px;
            text-align: center;
            line-height: 24px;
            color: #999999;
            background-color: #fff;
            font-weight: 700;
          }
          &:hover{
            .icon{
              color: #FFFFFF;
              background-color: #E41135;
            }
          }
        }
        &:hover{
          .content{
            display: block;
          }
        }
      }
    }
    .noproduct{
      width: 100%;
      background-color: #fff;
      padding: 20px 40px;
      border: 1px solid #BABABA;
      margin-bottom: 20px;
      .text{
        font-size: 14px;
        color: #333333;
      }
      .noproductText{
        color: #E41135;
      }
    }
    .conditionContent{
      border: 1px solid #D9D9D9;
      width: 100%;
      .brandbox{
        border-bottom: 1px solid #D9D9D9;
        position: relative;
        background-color: #F8F8F8;
        .brandboxwrap{
          .name{
            float: left;
            font-size: 12px;
            width: 100px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 11px 0 0 20px; 
            line-height: 16px;
            height: 100%;
          }
          .letterBox{
            margin-left: 100px;
            padding: 11px 0 0 5px;
            height: 37px;
            background: #fff;
            margin-bottom: 0;
            li{
              margin: 0 5px;
              float: left;
              padding: 5px;
              cursor: pointer;
              font-size: 12px;
              line-height: 16px;
              height: 26px;
              color: #333;
              border: 1px solid transparent;
              &:hover{
                color: #e31436;
                border: 1px solid #e31436;
              }
            }
          }
          .hdbrands{
            margin-left: 100px;
            padding: 8px 152px 4px 0;
            background-color: #fff;
            .brandcontent{
              position: relative;
              overflow: hidden;
              height: 62px;
              .brands{
                width: 936px;
                overflow: auto;
                padding-left: 15px;
                .multiItem{
                  display: inline-block;
                  position: relative;
                  box-sizing: border-box;
                  margin-right: 20px;
                  padding: 6px 0 5px 2px;
                  width: 132.6px;
                  border: 1px solid transparent;
                  font-size: 12px;
                  line-height: 16px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  cursor: pointer;
                  &:hover{
                    color: #E41135FF;
                  }
                }
                .multi-active{
                  padding-right: 25px;
                  border: 1px solid #e31436;
                  color: #e31436;
                  padding:2px 15px 2px 2px;
                }
                span.multi-active:after {
                  font-family: iconfont!important;
                  font-size: 12px;
                  content: "\2716";
                  position: absolute;
                  right: 0px;
                  bottom: 0px;
                  padding: 0 3px;
                  background: #e31436;
                  color: #fff;
                  font-weight: 200;
                  height: 20px;
                  line-height: 20px;
                  text-align: center;
                }
              }
            }
            .button-group{
              font-size: 0;
              text-align: center;
              padding-bottom: 10px;
              margin-top: 5px;
              div{
                display: inline-block;
                width: 60px;
                height: 26px;
                border: 1px solid #D9D9D9;
                font-size: 12px;
                line-height: 24px;
                border-radius:2px;
                text-align: center;
                margin-right: 10px;
                cursor: pointer;
              }
              .saveBtn{
                background-color: #F5F5F5;
                color: #999999;
              }
              .ative{
                background-color: #e31436;
                color: #fff;
              }
            }
          }
          .autoheight{
            .brandcontent{
              height: auto;
              .brands{
                width: 936px;
                max-height: 124px;
                overflow-y: auto;
                overflow-x: hidden;
                padding-left: 15px;
                line-height: 16px;
              }
            }
          }
          .morewrap{
            position: absolute;
            top: 11px;
            right: 20px;
            div{
              display: inline-block;
              width: 52px;
              height: 26px;
              border: 1px solid #D9D9D9;
              padding: 5px;
              line-height: 16px;
              cursor: pointer;
              .plus{
                margin-bottom: 3px;
                width: 12px;
                height: 12px;
                color:#D9D9D9; 
              }
              .downUp{
                color:#D9D9D9; 
              }
              &:hover{
                color: #E41135;
                border: 1px solid #E41135;
                .plus{
                  color: #E41135;
                }
                .downUp{
                  color:#E41135; 
                }
              }
            }
            .unfold{
              margin-left: 10px;
            }
          }
        }
      }
      .typebox{
        position: relative;
        background-color: #F8F8F8;
        .typeboxwrap{
          position: relative;
          overflow: hidden;
          .name{
            float: left;
            font-size: 12px;
            width: 100px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 11px 0 0 20px; 
            line-height: 16px;
            height: 100%;
          }
          .hdtype{
            margin-left: 100px;
            padding: 0 152px 0 0;
            background-color: #fff;
            min-height: 36px;
            .typecontent{
              position: relative;
              overflow: hidden;
              height: 62px;
              .types{
                width: 936px;
                padding-left: 15px;
                .multiItem{
                  display: inline-block;
                  position: relative;
                  box-sizing: border-box;
                  margin-right: 20px;
                  padding: 6px 0 5px 0;
                  width: 132.6px;
                  border: 1px solid transparent;
                  font-size: 12px;
                  line-height: 16px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  cursor: pointer;
                  &:hover{
                    color: #E41135FF;
                  }
                }
              }
            }
            .mintypecontent{
              height: 36px;
              .types{
                .multiItem{
                  padding: 10px 0 10px 0;
                }
              }
            }
          }
          .autoheight{
            .typecontent{
              height: auto;
              .types{
                width: 936px;
                max-height: 124px;
                overflow-y: auto;
                overflow-x: hidden;
                padding-left: 15px;
                line-height: 16px;
              }
            }
          }
          .morewrap{
            position: absolute;
            top: 11px;
            right: 20px;
            div{
              display: inline-block;
              width: 52px;
              height: 26px;
              border: 1px solid #D9D9D9;
              padding: 5px;
              line-height: 16px;
              cursor: pointer;
              .plus{
                margin-bottom: 3px;
                width: 12px;
                height: 12px;
                color:#D9D9D9; 
              }
              .downUp{
                color:#D9D9D9; 
              }
              &:hover{
                color: #E41135;
                border: 1px solid #E41135;
                .plus{
                  color: #E41135;
                }
                .downUp{
                  color:#E41135; 
                }
              }
            }
            .unfold{
              margin-left: 10px;
            }
          }
        }
      }
    }
    .rankCondition{
      width: 100%;
      height: 36px;
      margin: 16px 0;
      background-color: #fff;
      border:1px solid #D9D9D9;
      display: flex;
      .left{
        width: 200px;
        div{
          display: inline-block;
          width: 66px;
          cursor: pointer;
          margin: -1px;
          margin-left: 0;
          line-height: 16px;
          padding: 10px 5px; 
          border: 1px solid #D9D9D9;
          height: 36px;
          text-align: center;
          img{
            display: none;
            margin-bottom: 3px;
            margin-left: 4px;
          }
        } 
        .ative{
          position: relative;
          color: #E41135;
          border: 1px solid #E41135;
          img{
            display: inline-block;
            margin-bottom: 3px;
            margin-left: 4px;
          }
        }
      }
      .right{
        flex: 1;
        color: #BABABA;
        height: 36px;
        line-height: 36px;
        .priceBox{
          width: 124px;
          float: left;
          position: relative;
          z-index: 10;
          .priceInputs{
            position: absolute;
            left: 0px;
            top: -1px;
            .btn{
              display: none;
              background-color: #fff;
              .clear{
                color: #51b8f1;
                margin-left: 10px;
                cursor: pointer;
              }
              .sure{
                display: inline-block;
                width:40px;
                height: 20px;
                color: #fff;
                background-color: #E41135FF;
                text-align: center;
                line-height: 20px;
                margin-left: 45px;
                cursor: pointer;
              }
            }
            .input{
              input{
                width: 50px;
                height: 22px;
                border: 1px solid #BABABA;
                padding-left: 4px;
                color: #999999;
              }
              i{
                display: inline-block;
                width: 10px;
                height: 1px;
                color: #D9D9D9;
                margin: 0 3px;
              }
            }
            &:hover .btn{
              display: block;
            }
          }
        }
      }
    }
  }
  .product{
    width: 100%;
    background-color: #F2F2F2;
    .ModelGoodsItem{
			float: left;
      margin-bottom: 10px;
      &:hover{
        .ProductItem{
          border: 1px solid #E41135;
          box-shadow: 1px 2px 3px rgba(0,0,0,.2);
        }
      } 
		}
		.marR{
			margin-right: 10px;
		}
  }
  .page{
    height: 70px;
    background: #fff;
    padding-top: 13px;
  }
}