.refundOrderDetail{
  background-color: #F2F2F2;
  color: #333333;
  .guideTitle{
    height: 50px;
    line-height: 50px;
    background-color: #F2F2F2;
    font-size: 12px;
    .homePage{
      a:hover{
        border-bottom:1px solid #333333;
      }
    }
    .userCenter{
      a:hover{
        border-bottom:1px solid #333333;
      }
    }
  }
  .detailBox{
    background-color: #fff;
    padding: 30px 50px 50px 50px;
    .title1{
      font-size: 18px;
      font-weight: Bold;
    }
    .orderStatus{
      width: 100%;
      height: 103px;
      .progressBar{
        margin: 30px 0 0 40px;
      }
    }
    .refundorder{
      width: 100%;
      height:234px;
      background:rgba(255,255,255,1);
      border:1px solid rgba(217,217,217,1);
      span{
        display: block;
      }
      .refundStatus{
        position: relative;
        padding:20px 0 0 20px;
        height: 100%;
        width: 50%;
        float: left;
        .orderNum{
          line-height: 24px;
        }
        .status{
          color: #43A115;
          font-size: 24px;
          line-height: 30px;
          margin:20px 0 10px 0 ;
        }
        .text{
          width:413px;
          color: #E41135;
          line-height: 24px;
        }
        .refundTime{
          position: absolute;
          bottom: 20px;
          color: #666666;
          line-height: 20px;
        }
      }
      .refundInfo{
        float: right;
        width: 50%;
        padding-top: 20px;
        line-height: 25px;
        .leftTitle{
          float: left;
          span{
            display: inline-block;
          }
          div{
            line-height: 19px;
          }
          .refundImgs{
            display: block;
            margin-left: 70px;
            img{
              width: 70px;
              width: 70px;
            }
          }
        }
        .title{
          font-weight: 700;
          margin-bottom: 20px;
        }
        .refundreason{
          margin:10px 0;
        }
        .describe{
          span{
            display: inline-block;
          }
        }
      }
    }
    .returnInfo{
      width: 100%;
      height: 244px;
      border:1px solid #D9D9D9;
      padding: 20px 30px;
      margin-top: 40px;
      .title{
        width: 100%;
        line-height: 22px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      .left{
        float: left;
        width: 50%;
      }
      .right{
        float: left;
        width: 50%;
      }
      p{
        line-height: 19px;
        margin-bottom: 8px;
        .fillBtn{
          display: inline-block;
          width:50px;
          height:20px;
          background-color: #fff;
          border:1px solid #E41135;
          border-radius:2px;
          text-align: center;
          color: #E41135;
        }
      }
    }
    .orderInfo{
      border:1px solid #D9D9D9;
      margin-top: 20px;
      width: 100%;
      .tableTitle{
        background-color:#F2F2F2;
        height: 32px;
        width: 100%;
        font-size: 12px;
        color: #666666;
        text-align: center;
        line-height: 32px;
        .clearfix{
          width: 100%;
          .tableTitleItem{
            float: left;
            width: 19%;
          }
          .long{
            width: 43%;
          }
        }
      }
      .tableItem{
        width: 100%;
        padding: 0px 27px 0 20px;
        table{
          width: 100%;
          tbody{
            width: 100%;
            tr{
              height: 136px;
              width: 100%;
              text-align: center;
              border-bottom: 1px solid#E5E5E5;
              .goodItem{
                width: 19%;
              }
              .img{
                width: 11%;
                text-align: left;
                img{
                  width: 96px;
                }
              }
              .title{
                width: 32%;
                span{
                  text-align: left;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
              }
              .count{
                width: 20.5%;
              }
            }
          }
        }
      }
    }
  }
}