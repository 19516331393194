.templateC{
  width: 100%;
  background-color: #F2F2F2;
  height: 540px;
  .inner{
    .recTitle{
      height: 61px;
      line-height: 61px;
      color: #333333;
      font-size: 22px;
      font-weight:bold;
    }
    .recClassify{
      width: 1190px;
      height: 440px;
      .leftArea{
        width: 390px;
        height: 440px;
        float: left;
        .item{
          position: relative;
          width: 390px;
          height: 100%;
          text-align: center;
          .mainTitle{
            width: 100%;
            position: absolute;
            bottom: 96px;
            text-align: center;
            color: #666666;
            font-size: 18px;
            line-height: 30px;
          }
          .subTitle{
            width: 100%;
            position: absolute;
            bottom: 34px;
            text-align: center;
            color:#333333;
            font-size: 24px;
            line-height: 38px;
          }
          .itemImg{
            width: 100%;
            height: 100%;
          }
        }
      }
      .rightArea{
        float: right;
        width: 800px;
        height: 440px;
        background-color: #fff;
        .item{
          position: relative;
          float: left;
          width: 200px;
          height: 220px;
          border-bottom: 1px solid #efefef;
          border-right: 1px solid #efefef;
          .itemImg{
            width: 138px;
            margin:20px 31px 12px 31px;
          }
          .title1{
            width: 100%;
            position: absolute;
            bottom: 32px;
            text-align: center;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 0;
          }
          .subTitle{
            width: 100%;
            position: absolute;
            bottom: 10px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
            margin-bottom: 0;
          }
          .mask{
            display: none;
            width: 100%;
            height: 100%;
            position: absolute;
            top:0;
            left: 0;
            background-color:rgba(00,00,00,.8);
            .clickTo{
              display: inline-block;
              width: 80px;
              height: 28px;
              line-height: 26px;
              text-align: center;
              background-color: #E41135;
              color: #FFFFFF;
              font-size: 12px;
              border-radius: 21px;
              position: absolute;
              bottom: 53px;
              left: 60px;
              padding-left:2px;
            }
            .title2{
              font-weight: bold;
              width: 100%;
              position: absolute;
              bottom: 107px;
              text-align: center;
              font-size: 15px;
              line-height: 24px;
              margin-bottom: 0;
              color: #FFFFFF;
            }
          }
          &:hover{
            .mask{
              display: block;
            }
            .title1{
              display: none;
            }
          }
        }
      } 
    }
  }
}