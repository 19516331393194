.cancelOrderBox{
	display: none;
	width: 100%;
	height: 100%;
	z-index: 1000000;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	font-size: 14px;
	.sureAndCancelInner{
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
  	width: 390px;
	  height: 205px;
	  border-radius: 2px;
	  background-color: #ffffff;
	  box-shadow:0px 0px 18px rgba(0,0,0,0.25);
	  .title{
	  	width: 100%;
	  	height: 40px;
	  	line-height: 40px;
	  	position: relative;
	  	border-bottom: 1px solid #E5E5E5;
	  	.boxName{
	  		margin-left: 14px;
	  		vertical-align: middle;
	  	}
	  	.closeBtn{
	  		cursor: pointer;
	  		position: absolute;
	  		top: 0;
	  		bottom: 0;
	  		right: 14px;
        margin: auto;
	  	}
	  }
	  .section{
	  	position: absolute;
	  	bottom: 0;
	  	height: 164px;
	  	width: 100%;
      top: 41px;
			.alertText{
				width: 100%;
				margin-top: 31px;
				color: #333333;
				img{
					margin: 0 16px 0 119px;
				}
			}
			.red{
				color: #ea3737;
			}
			.opBtn{
				width: 100%;
				position: absolute;
        bottom: 36px;
        left:0px;
				text-align: center;
				.cancel{
					margin: 0 10px;
          cursor: pointer;
          padding: 6px 0 0 0;
					text-align: center;
					display: inline-block;
					width: 88px;
				  height: 32px;
				  border-radius: 4px;
				  background-color: #fff;
				  border: solid 1px #D9D9D9;
          line-height: 20px;
          color: #666666;
				}
				.sure{
					margin: 0 10px;
          cursor: pointer;
          padding: 6px 0 0 0;
					line-height: 20px;
					text-align: center;
					color: #fff;
					display: inline-block;
					width: 88px;
				  height: 32px;
				  border-radius: 4px;
				  background-color: #E41135;
          border:1px solid rgba(228,17,53,1);
				}
			}
	  }
	}
}