.LimitBuyDetail{
	background: #F2F2F2;
	padding-bottom: 80px;
	.limitInfo{
		background-image: url(../../images/bg_xianshiqianggou.png);
		background-size: cover;
		height: 70px;
		line-height: 70px;
		color: #fff;
		text-align: center;
		font-size: 24px;
		position: relative;
		.titleIcon{
			position: relative;
			z-index: 2;
			margin-top: -6px;
		}
		.title{
			position: relative;
			z-index: 2;
		}
		.huodongTime{
			line-height: 64px;
			margin: 0px 14px 0 30px;
			font-size: 14px;
			display: inline-block;
		}
		.daojisbox{
			display: inline-block;
		}
		.daojishiBox{
			display: inline-block;
			position: relative;
			height: 32px;
			margin-top: 5px;
			.maohao{
				text-align: center;
				width: 10px;
				line-height: 30px;
				height: 32px;
				font-size: 24px;
				height: 100%;
				background: #FA2D70;
				position: absolute;
			}
			.l{
				top: 0;
				right: 31px;
			}
			.r{
				top: 0;
				right: 74px;
			}
			.ant-statistic-content{
				line-height: 32px;
				font-size:18px;
				font-family:Helvetica;
			}
		}
	}
	.inner{
		.ModelGoodsItem{
			float: left;
			margin-bottom: 10px;
		}
		.marR{
			margin-right: 10px;
		}
		.page{
			height: 70px;
			background: #fff;
			padding-top: 13px;
		}
		.limitBuyRoundBox{
			color: #fff;
			text-align: center;
			margin-bottom: 9px;
			.roundItem{
				cursor: pointer;
				padding-top: 8px;
				height: 56px;
				background: #333333;
				display: inline-block;
				vertical-align: top;
				.roundTime{
					line-height: 24px;
					font-size: 20px;
				}
				.roundStatus{
					font-size: 12px;
				}
				.sanjiao{
					margin-top: -3px;
				}
			}
			.active{
				background: #E41135;
			}
		}
	}
}