.pop_view_bg{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
  top: 0;
  left: 0;
  .white_back{
    width: 500px;
    height: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -250px;
    margin-left: -250px;
    background-color: white;
    clear: both;
    .real_name_title{
      margin-left: 10px;
      font-size: 14px;
      line-height: 40px;
      height: 40px;
    }
    .top_line{
      margin: 0px;
      height: 1px;
      background-color: #e5e5e5;
    }
    .info_bg{
      margin-top:20px;  
      position: relative;
      height: 32px;
      .left_star{
        top: 5px;
        position:absolute;
        left: 42px;
        color: #e41135;
      }
      .left_title{
        top: 5px;
        width: 120px;
        font-size: 14px;
        text-align: right;
        position: absolute;
      }
      .user_name{
        width: 308px;
        height: 32px;
        border: 1px #d9d9d9 solid;
        position: absolute;
        left: 124px;
        font-size: 14px;
        line-height: 30px;
        padding-left: 10px;
      }
    }
    .photo_bg{
      height: 252px;
      margin-top:20px;  
      position: relative;
      .left_title{
        top: 5px;
        width: 120px;
        font-size: 14px;
        text-align: right;
        position: absolute;
      }
      .updload_bg{
        height: 135px;
        position: absolute;
        left: 130px;
        right: 0;    
        clear: both;
        .item_bg{
          float: left;
          margin-right: 20px;
          margin-top: 10px;
          .ant-upload.ant-upload-select-picture-card > .ant-upload{
            padding: 0px;
          }
          .ant-upload.ant-upload-select-picture-card{
            width: 144px;
            height: 90px;
            border-width: 0px;
            margin: 0px;
            padding: 0px;
          }
          .upload_img_bg{
            width: 144px;
            height: 90px;
            border: 1px rgba(0, 0, 0, 0.15) solid;  
            border-radius: 4px;
            .upload_img{
              width: 144px;
              height: 90px;
            }
          }
          .upload_title{
            color: #666666;
            font-size: 14px;
            width: 144px;
            margin-top: 10px;
            text-align: center;
          }
        }    
        
      }
      .bottom_tip_bg{
        line-height: 22px;
        position: absolute;
        left: 130px;
        top:150px; 
        .tip_info{
          font-size: 14px;
          color: #333333;
          margin-bottom: 5px;
        }
        .submit_btn{
          width: 102px;
          line-height: 32px;
          border-radius: 4px;
          background-color: #e41135;
          margin-top: 35px;
          text-align: center;
          color: white;
          &:hover{
            opacity: 0.8;
          }
        }
      }
    }
    .bottom_btn_bg{
      display: flex;
      justify-content: center;
      margin:0px;
      .red_btn{
        width: 88px;
        line-height: 32px;
        border-radius: 4px;
        background-color: #e41135;
        margin-top: 5px;
        text-align: center;
        color: white;
        margin-right: 20px;
        cursor: pointer;
        &:hover{
          opacity: 0.8;
        }
      }
      .balck_btn{
        width: 88px;
        line-height: 32px;
        border-radius: 4px;
        border: 1px #d9d9d9 solid;
        margin-top: 5px;
        text-align: center;
        color: #666666;
        cursor: pointer;
        &:hover{
          opacity: 0.8;
        }
      }
    }
  }
}