.ProductItemLimit{
  position: relative;
  width: 290px;
  height: 436px;
  padding: 20px;
  background-color: #fff;
  .tag{
    position: absolute;
    top: 0;
    right: 0;
  }
  .Img{
    width: 250px;
    height: 250px;
    .proImg{
      width: 100%;
      height: 100%;
    }
  }
  .proContent{
    .Title{
      font-size: 16px;
      color:#333333;
      line-height: 25px;
      height: 50px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .progress{
      margin-top: 23px;
      height: 32px;
      width: 126px;
      float: left;
      .progressNum{
        margin-bottom: -5px;
        font-size: 12px;
        color: #666666;
      }
    }
    .btn{
      cursor: pointer;
      margin-top: 23px;
      width: 88px;
      height: 32px;
      line-height: 32px;
      background: #E41135;
      color: #fff;
      border-radius: 4px;
      text-align: center;
      float: right;
    }
    .price{
      height: 36px;
      color: #E41135;
      .symbol{
        vertical-align: middle;
        font-size: 14px;
      }
      .number{
        vertical-align: middle;
        display: inline-block;
        height: 36px;
        line-height: 36px;
        font-size: 24px;
      }
      .discount{
        vertical-align: middle;
        padding: 0 8px;
        display: inline-block;
        line-height: 18px;
        height: 18px;
        border-radius:9px;
        background-color: #FFEBEE;
        font-size: 12px;
        margin-left: 5px;
        color: #E41135;
      }
      .oriPrice{
        display: inline-block;
        height: 18px;
        line-height: 18px;
        margin-top: 9px;
        text-decoration: line-through;
        color: #999999;
        font-size: 12px;
        float: right;
      }
    }
    .ant-progress-inner{
      background: #E5E5E5;
    }
  }
}