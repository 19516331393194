.pagerBox{
	width: 100%;
	position: relative;
	background: #fff;
	height: 44px;
	display: none;
	ul{
		right: 176px;
		height: 28px;
		overflow: hidden;
		box-sizing: border-box;
		top: 0;
		bottom: 0;
		margin: auto 0;
		position: absolute;
	}
	.pagerItem{
		cursor: pointer;
		margin: 0 3px;
		box-sizing: border-box;
		padding: 0 10px;
		border: 1px solid #e4e4e4;
		float: left;
		text-align: center;
		line-height: 26px;
		
	}
	.totalNum{
		cursor: pointer;
		margin: 0 3px;
		box-sizing: border-box;
		font-size:13px;
		line-height: 28px;
		float: left;
		text-align: center;
	}
	.next,.prew{
		padding: 0;
		width: 28px;
		height: 28px;
		box-sizing: border-box;
	}
	.current{
		background: #eb6767;
		color: #fff;
	}
	.pageEle{
		right: 0;
		height: 28px;
		line-height: 26px;
		box-sizing: border-box;
		position: absolute;
		right: 80px;
		top: 0;
		bottom: 0;
		margin: auto;
		input{
			cursor: pointer;
			text-align: center;
			display: inline-block;
			box-sizing: border-box;
			margin-right: 8px;
			width: 48px;
			border: 1px solid #dedede;
			height: 28px;
			outline: none;
			vertical-align: middle;
		}
	}
	.turnTo{
		cursor: pointer;
		border-radius: 2px;
		display: block;
		width: 51px;
		text-align: center;
		line-height: 26px;
		border: 1px solid #dedede;
		box-sizing: border-box;
		right: 14px;
	}
}