.bottom_footer{
	width: 100%;
	height: 403px;
  background-color: #1a1a1a;
  clear:both;
	.top_inner{
    width: 1313.4px;
    margin: 0 auto;
    height: 130px;
    display: flex;
    justify-items: center;
		.top_item{
			height:60px ;
      width: 210px;
      margin-right: 61.6px;
      margin-left: 61.6px;
      margin-top: 35px;
      clear: both;
			.iconImg{
				width: 60px;
        height: 60px;
        float: left;
			}
			.titleLabel{
				color: white;
        font-size: 20px;
        float: left;
        margin-left: 10px;
			}
			.subTitleLable{
				color: #bababa;
        font-size: 16px;
        margin-top: 9px;
        margin-left: 10px;
        float: left;
        &:hover{
          color: white;
        }
			}
    }
  }
  .mid_line{
    width: 1190px;
    height: 1px;
    background-color: #e5e5e5;
    opacity: 0.2;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
  .mid_inner{
    height: 223px;
    width: 1190px;
    margin: 0 auto;
    .mid_item{
      height: 223px;
      width: 198.3px;
      float: left;
      .mid_big_title{
        margin-top: 33px;
        margin-left: 21px;
        font-size: 16px;
        color: white;
      }
      .mid_small_title{
        margin-top: 20px;
        margin-left: 21px;
        color: #bababa;
        font-size: 12px;
        &:hover{
          color: white;
        }
      }
      .mid_qrcode_img{
        width: 120px;
        height: 127px;
        margin-top: 33px;
        margin-left: 21px;
      }
    }
  }
  .bottom_line{
    width: 100%;
    height: 1px;
    margin: 0px;
    opacity: 0.2;
    background-color: #e5e5e5;
  }
  .bottom_inner{
    height: 48px;
    width: 600px;
    margin: 0 auto;
    .bottom_tip_left{
      margin-top: 15px;
      // float: left;
      display: inline-block;
      font-size: 12px;
      color: white;
    }
    .bottom_tip_right{
      margin-top: 15px;
      // float: right;
      display: inline-block;    
      margin-left: 39px;
      font-size: 12px;
      color: white;
      a{
        color: #fff;
      }
    }
  }
}