.nav_head{
  width: 100%;
  height: 115px;
  z-index: 500;
  background-color: white;
  .inner{
    height: 110px;
  }
  .nav_logo{
    margin-left: 38px;
    float: left;
    margin-top: 18px;
	width:100px;
	height: 80px;
  }
  .nav_mid_inner{
    position: relative;
    margin-top: 23px;
    margin-left: 137px;
    float: left;
    height: 73px;
    .search_back_view{
      width: 604px;
      height: 43px;
      border-radius: 20px;
      border: 2px solid #e41135;
      .search_img{
        width: 26px;
        height: 26px;
        margin-top: 7px;
        margin-left: 14px;
        float: left;
      }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
      .search_input{
        color: #333;
        width: 470px;
        vertical-align: middle;
        height: 38px;
        line-height: 40px;
        margin: 1px 0px 0px 4px;
        float: left;
      }
      .search_btn{
        cursor: pointer;
        font-size: 15px;
        width: 86px;
        height: 40px;
        border-radius: 0px 20px 20px 0px;
        border-width: 0px;
        text-align: center;
        line-height: 40px;
        background-color: #e41135;
        float: right;
        color: white;
        margin-right: -2px;
      }
    } 
    .tag_back_view{
      width: 518px;
      height: 28px;
      overflow: hidden;
      .activity_tag{
        margin-top: 12px;
        margin-left: 21px;
        float: left;
        .red_tag{
          border-radius:2px;
          background-color: #e41135;
          padding-left:  2px;
          padding-right: 2px;
          height: 14px;
          line-height: 14px;
          color: white;
          float: left;
          font-size: 12px;
        }
        .red_title_tag{
          height: 14px;
          line-height: 14px;
          margin-left: 5px;
          color:#e41135;
          float: left;
          font-size: 12px;
        }
      }
      .ordinary_tag{
        margin-top: 12px;
        margin-left: 21px;
        color: #666666;
        font-size: 12px;
        line-height: 14px;
        height: 14px;
        float: left;
        &:hover{
          color: #e41135;
        }
      }
    }
    .searchLenovoBox{
      position: absolute;
      top:3px;
      left:-13px;
      margin:40px;
      width:490px;
      max-height: 281px;
      overflow: hidden;
      border: 1px solid #999999;
      background-color: #fff;
      z-index: 9999;
      font-size: 12px;
      .recentSearch{
        width: 100%;
        height: 28px;
        padding: 0 10px;
        .recent{
          float: left;
          color: #999999;
          line-height: 28px;
        }
        .delIcon{
          float:right;
          cursor: pointer;
          .red{
            display: none;
          }
          &:hover{
            .red{
              display: block;
            }
            .white{
              display: none;
            }
          }
        }
      }
      .searchRecord{
        margin-bottom: 0;
        width: 100%;
        .searchText{
          width: 100%;
          height: 28px;
          display: block;
          color:#333333;
          font-size: 12px;
          line-height: 16px;
          padding: 0 10px;
          .text{
            width: 94%;
            float: left;
            margin-top: 6px;
            cursor: pointer;
          }
          .delete{
            width: 6%;
            float: right;
            color: #666666;
            display: none;
            cursor: pointer;
            margin-top: 6px;
            &:hover{
              color: #E41135;
            }
          }
          &:hover{
              background-color: #F2F2F2;
              .text{
                color: #E41135;
              }
              .delete{
                display: inline-block;
              }
          }
        }
      }
    }
  }
  .nav_right_inner{
    margin-left: 60px;
    margin-top: 23px;
    float: left;
    width: 140px;
    height: 40px;
    border-radius: 20px;
    border: 2px solid #e41135;
    .shop_cart_img{
      width: 26px;
      height: 26px;
      margin-top: 6px;
      margin-left: 20px;
      float: left;
      vertical-align: middle;
    }
    .shop_cart_title{
      line-height: 40px;
      margin-left: 1px;
      font-size: 14px;
      color: #e41135;
      float: left;
      vertical-align: middle;
    }
    .shop_cart_num{
      padding: 0 5px;
      height: 20px;
      margin-top: 8px;
      margin-left: 8px;
      border-radius: 10px;
      background-color: #e41135;
      color: white;
      font-size: 12px;
      text-align: center;
      line-height: 20px;
      float: left;
      vertical-align: middle;
    }
  }
}
  