.CouponItem{
  position: relative;
  width: 362px;
  height: 105px;
  background: url(../../images/bg_coupon.png);
  .yilingqu{
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 26px;
  }
  .couponLeft{
    position: absolute;
    color: #fff;
    top: 1px;
    left: 1px;
    width: 103px;
    height: 103px;
    background: #FA5471;
    border-radius:4px 0px 0px 4px;
    text-align: center;
    .symbol{
      display: inline-block;
      margin-top: 30px;
      font-size: 14px;
    }
    .money{
      font-size: 28px;
      font-weight: bold;
    }
    .tiaojian{
      display: inline-block;
      font-size: 14px;
    }
  }
  .couponMid{
    padding-left: 10px;
    position: absolute;
    top: 1px;
    left: 104px;
    width: 193px;
    height: 103px;
    .couponTitle{
      position: absolute;
      top: 20px;
      font-size: 18px;
      color: #333;
      line-height: 24px;
    }
    .couponTime{
      font-size: 12px;
      color: #999;
      bottom: 20px;
      position: absolute;
    }
  }
  .couponRight{
    position: absolute;
    cursor: pointer;
    width: 40px;
    top: 1px;
    height: 103px;
    right: 0;
    text-align: center;
    .getNow{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 60px;
      line-height: 20px;
      font-size: 14px;
      color: #E41135;
      display: inline-block;
      width: 14px;
    }
  }
}