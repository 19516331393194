.RankCenter{
	background: #F2F2F2;
	padding-bottom: 80px;
	.titleBox{
		height: 70px;
		line-height: 70px;
		color: #fff;
		text-align: center;
		font-size: 24px;
		position: relative;
		.titleIcon{
			position: relative;
			z-index: 2;
			margin-top: -6px;
		}
		.title{
			position: relative;
			z-index: 2;
		}
		.bg_img{
			left: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}
	.inner{
		.ModelGoodsItem{
			float: left;
			margin-bottom: 10px;
		}
		.marR{
			margin-right: 10px;
		}
		.page{
			height: 70px;
			background: #fff;
			padding-top: 13px;
		}
		.couponBox{
			margin: 26px 26px 0 0;
			float: left;
		}
		.marL{
			margin-left: 26px;
		}
	}
}