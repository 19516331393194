.user_center_super_bg{
  background-color: #f2f2f2;
  padding-bottom: 42px;
  .user_center_top_bg{
    width: 100%;
    display: flex;
    justify-content: center;
    .user_center_left_bg{
      margin-top: 20px;
      margin-bottom: 20px;
      width: 190px;
      padding-bottom: 80px;
      background-color: white;
      .user_center_nomal_color{
        color: #333333;
        font-weight: 100px;
      }
      .user_center_red_color{
        color:#e41135;
        font-weight: bold;
      }
      .user_center_left_big_title{
        columns: #333333;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 15px;
        padding-bottom: 0px;
      }
      .user_center_section{
        margin-top: 15px;
        margin-bottom: 0px;
        padding-bottom: 0px;
        .user_center_left_item_bg{
          height: 30px;
          position: relative;
          cursor: pointer;
          .user_c_rec_display_none{
            display: none;
          }
          .user_c_rec_display_block{
            display: block;
          }
          .user_center_left_rectangle{
            width: 2px;
            height: 14px;
            background-color:#e41135; 
            position: absolute;
            left: 45px;
            top: 4px;
          }
          .user_c_small_title{
            font-size: 14px;
            position: absolute;
            left: 60px;
          }
        }
      }
    }
    .display_none{
      display: none;
    }
    .user_center_right_bg{
      margin: 20px 0px 20px 10px;
      background-color: white;
    }
  }
  .recormendBox{
    border: 1px solid #D9D9D9;
    .itemTitle{
      height: 50px;
      line-height: 50px;
      padding-left: 24px;
      color: #333;
      font-size: 18px;
      background: #F2F2F2;
    }
  }
}