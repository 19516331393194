.templateD{
  width: 100%;
  background-color:#F2F2F2;
  padding-bottom:40px;
  .inner{
    .recTitle{
      height: 61px;
      line-height: 61px;
      color: #333333;
      font-size: 22px;
      font-weight:bold;
    }
    .recClassify{
      width: 1190px;
      height: 508px;
      .leftArea{
        width: 390px;
        height: 508px;
        float: left;
        .item{
          position: relative;
          width: 390px;
          height: 508px;
          text-align: center;
          .itemImg{
            width: 100%;
            height: 100%;
          }
          .mainTitle{
            width: 100%;
            position: absolute;
            top:33px;
            color: #FFFFFF;
            font-size: 24px;
            line-height: 38px;
          }
          .subTitle{
            width: 100%;
            position: absolute;
            top:78px;
            color: #FFFFFF;
            font-size: 18px;
          }
        }
      }
      .rightArea{
        float: right;
        width: 800px;
        height: 440px;
        .section1{
          width: 600px;
          float: left;
          .item{
            position: relative;
            float: left;
            width: 300px;
            height: 254px;
            border-bottom: 1px solid #E5E5E5;
            border-right: 1px solid #E5E5E5;
            text-align: center;
            .itemImg{
              width: 100%;
              height: 100%;
            }
            .mainTitle{
              width: 100%;
              position: absolute;
              top:20px;
              color: #333333;
              font-size: 22px;
              line-height: 31px;
            }
            .subTitle{
              width: 100%;
              position: absolute;
              top:56px;
              color: #333333;
              font-size: 16px;
            }
          }
        }
        .section2{
          float: right;
          width: 200px;
          height: 508px;
          border-bottom: 1px solid #E5E5E5;
          background-color: #fff;
          .brandRec{
            width: 200px;
            height: 52px;
            line-height: 52px;
            padding: 13px 11px 15px 11px;
            color: #333333;
            p{
              font-size: 16px;
              font-weight: bold;
              line-height: 24px;
            }
          }
          .ant-carousel{
            .slick-slider{
              .slick-dots{
                text-align: unset;
                top:-28px;
                left:163px;
                height: 6;
                li{
                  button{
                    border-radius: 3px;
                    width: 6px;
                    height: 6px;
                    background-color: rgba(00,00,00,.2);
                    opacity: 1;
                  }
                }
                .slick-active{
                  button{
                    background-color:#E41135;
                  }
                }
            }
            
          }
          .item{
            position: relative;
            width: 200px;
            height: 152px;
            border-top: 1px solid #E5E5E5;
            text-align: center;
              img{
                margin: 16px 0 0 55px;
                width: 90px;
                height: 90px;
              }
            }
            .title{
              position: absolute;
              bottom: 12px;
              width: 100%;
              text-align: center;
              color: #333333;
              font-size: 14px;
              font-weight: bold;
              line-height: 24px;
              margin-bottom: 0;
            }
          }
        }
      } 
    }
    .productD{
      background: #fff;
      .ProductTem{
        float: left;
        margin-left: 1.5px;
        &:nth-of-type(1){
          margin-left: 0;
        }
      }
    }
  }
}