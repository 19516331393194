.feedback_bg{
  height: 835px;
  margin: 0px;
  width: 100%;
  position: relative;
  background-color: #f2f2f2;
  .success_bg{
    position: absolute;
    left: 50%;
    margin-left: -595px;
    top: 21px;
    width: 1190px;
    height: 735px;
    background-color: white;
    z-index: 100;
    .title{
      color: #333333;
      font-size: 18px;
      line-height: 60px;
      text-align: center;
      width: 1190px;
    }
    .check{
      width: 24px;
      height: 24px;
      position: absolute;
      left: 339px;
      top: 131px;
    }
    .success_tip{
      position: absolute;
      left: 370px;
      top: 131px;
      color: #333333;
      font-size: 14px;
    }
    .back_home{
      position: absolute;
      top: 224px;
      left: 530px;
      width: 130px;
      height: 36px;
      background-color: #e41135;
      border-radius: 4px;
      color: white;
      line-height: 36px;
      text-align: center;
    }
  }
  .white_bg{
    position: absolute;
    left: 50%;
    margin-left: -595px;
    top: 21px;
    width: 1190px;
    height: 735px;
    background-color: white;
    .title{
      color: #333333;
      font-size: 18px;
      line-height: 60px;
      text-align: center;
      width: 1190px;
    }
    .text_bg{
      border:1px #999999 solid;
      width: 900px;
      height: 299px;
      margin: 30px 145px 0px 145px;
      .text_content{
        color: #333333;
        margin: 20px 20px 0px 20px;
        width: 860px;
        height: 245px;
      }
      .text_num{
        margin: 0px 20px 0px 0px;
        width: 880px;
        text-align: right;
        color: #999999;
        font-size: 12px;
      }
    }
    .ant-upload-list-picture-card .ant-upload-list-item{
      width:140px;
      height: 140px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item{
      padding:0px;
    }
    .ant-upload{
      width:140px;
      height: 140px;
      padding:0px;
    }
    .select_pic_bg{
      margin: 20px ;
      margin: 20px 145px 0px 145px;
      height: 140px;
      width: 900px;
    }
    .submit{
      margin: 50px 0px 0px 530px;
      width: 130px;
      height: 36px;
      background-color: #e41135;
      color: white;
      font-size: 14px;
      text-align: center;
      line-height: 36px;
      border-radius: 4px;
    }
  }
}