.delete_address_pop_bg{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  .white_bg{
    width: 390px;
    height: 205px;
    background-color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -102px;
    margin-left: -195px;
    color: #333333;
    .del_address_title_bg{
      margin: 0x;
      height: 40px;
      clear: both;
      .del_address_title{
        margin-left: 10px;
        font-size: 14px;
        line-height: 40px;
        float: left;
      }
      .close{
        width: 20px;
        height: 20px;
        float: right;
        margin-right: 5px;
        margin-top: 8px;
      }
    }
    .top_line{
      margin: 0px;
      height: 1px;
      background-color: #e5e5e5;
    }
    .mid_content_bg{
      display: flex;
      justify-content: center;
      margin: 33px 20px 0px 20px;
      .mid_img{
        margin-right: 16px;
        width: 24px;
        height: 24px;
      }
      .mid_tip{
        font-size: 14px;
        color: #333333;
      }
    }
    .bottom_btn_bg{
      display: flex;
      justify-content: center;
      margin-top:30px;
      .red_btn{
        width: 88px;
        line-height: 32px;
        border-radius: 4px;
        background-color: #e41135;
        margin-top: 5px;
        text-align: center;
        color: white;
        margin-right: 20px;
      }
      .balck_btn{
        width: 88px;
        line-height: 32px;
        border-radius: 4px;
        border: 1px #d9d9d9 solid;
        margin-top: 5px;
        text-align: center;
        color: #666666;
      }
    }
  }
}