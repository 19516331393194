.help_center_super_bg{
	width: 100%;
	height: 880px;
	background-color: #f2f2f2;
	display: flex;
	justify-content: center;
	.help_center_left_bg{
		margin-top: 20px;
		height: 780px;
		width: 190px;
		background-color: white;
		.help_center_left_item_bg{
			.help_center_nomal_title_color{
				color: #333333
			}
			.help_center_red_title_color{
				color: #e41135;
			}
			.help_center_left_big_title_bg{
				margin-top: 0px;
				height: 50px;
				clear: both;
				.help_center_left_big_title{
					font-weight: bold;
					font-size: 14px;
					text-align: center;
					line-height: 50px;
					width: 50px;
					float: left;
					margin-left: 70px;
					cursor: pointer;
				}
				.help_center_more_img{
					width: 11px;
					height: 6px;
					float: left;
					margin-top: 22px;
					margin-left: 4px;
				}
			}
			.display_none{
				display: none;
			}
			.display_block{
				display: block;
			}
			.help_center_left_small_title{
				margin-top: 0px;
				font-size: 12px;
				text-align: center;
				height: 31px;
				line-height: 31px;
				cursor: pointer;
			}
		}
	}
	.help_center_right_bg{
		margin-top: 20px;
		margin-left: 10px;
		height: 780px;
		width: 990px;
		background-color: white;
	}
}