.help_center_content_bg{
  // margin-top: 20px;
  // margin-left: 10px;
  // height: 780px;
  // width: 990px;
  background-color: white;
  .help_r_title{
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: #333333;
    font-size: 18px;
  }
  .help_r_d_line{
    background-color: #e5e5e5;
    width: 910px;
    height: 1px;
    margin-left: 40px;
  }
  .help_content_bg{
    height: 720px;
    width: 990px;
    .help_content_title_bg{
      height: 49px;
      clear: both;
      .help_content_cirlce{
        background-color: #e41135;
        width: 10px;
        height: 10px;
        float: left;
        margin-top: 23px;
        margin-left: 30px;
        border-radius: 5px;
      }
      .help_content_title{
        width: 300px;
        height: 21px;
        float: left;
        margin-top: 16px;
        margin-left: 7px;
        color: #333333;
        font-size: 16px;
      }
    }
    .help_content{
      margin-left: 40px;
      margin-right: 30px;
      overflow: scroll;
      overflow-x: hidden;
      height: 630px;
      font-size:  14px;
      color: #666666;
    }
  }
}