.templateHotsale{
  width: 100%;
  background-color: #F2F2F2;
  .inner{
    .recTitle{
      height: 61px;
      line-height: 61px;
      color: #333333;
      font-size: 22px;
      font-weight:bold;
    }
    .recClassify{
      background-color: #F2F2F2;
      .ModelGoodsItem{
        float: left;
        margin-bottom: 10px;
        &:hover{
          .ProductItem{
            border: 1px solid #E41135;
            box-shadow: 1px 2px 3px rgba(0,0,0,.2);
          }
        } 
      }
      .marR{
        margin-right: 10px;
      }
    }
  }
}