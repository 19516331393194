.real_name_bg{
  width: 990px;
  color: #333333;
  .real_name_top_title{
    margin: 30px 50px 20px 50px;
    font-size: 18px;
    font-weight: bold;
  }
  .tip_bg{
    width: 890px;
    height: 103px;
    margin-left: 50px;
    border: 1px #faad14 solid;
    background-color: #fffbe6;
    clear: both;
    .tip_img{
      width: 16px;
      height: 16px;
      float: left;
      margin-top: 21px;
      margin-left: 20px;
    }
    .tip_info{
      width: 823px;
      margin: 20px 0px 0px 11px;
      font-size: 12px;
      float: left;
    }
  }
  .real_name_list_bg{
    margin-top: 30px;
    margin-left: 50px;
    .real_name_title{
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .list_content_bg{
      width: 890px;
      border: 1px #d9d9d9 solid;
      .list_title_bg{
        background-color: #f4f4f4;
        height: 42px;
        clear: both;
      }
      .list_conten_bg{
        background-color: white;
        height: 60px;
        clear: both;
      }
      .top_line{
        width: 100%;
        height: 1px;
        background-color: #d9d9d9;
      }
      .margin-12{
        margin-top: 12px;
      }
      .margin-20{
        margin-top: 20px;
      }
      .colum_title{
        float: left;
        text-align: center;
        width: 200px;
        display: flex;
        justify-content: center;
        .update_btn{
          color: #005aa0;
          cursor: pointer;
        }
        .mid_line{
          background-color: #005aa0;
          width: 1px;
          height: 14px;
          margin:4px 8px 0px 8px;
        }
      }
      .default_btn_true{
        width: 50px;
        margin-left: 0px;
        margin-top: 20px;
        float: left;
        line-height: 20px;
        text-align: center;
        color: white;
        background-color: #e41135;
        border-radius: 2px;
        cursor: pointer;
      }
      .default_btn_false{
        margin-left: 0px;
        margin-top: 20px;
        float: left;
        cursor: pointer;
      }
    }
  }
  .new_real_bg{
    margin: 40px 50px 50px 50px;
    .real_name_title{
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .info_bg{
      margin-top:20px;  
      position: relative;
      height: 32px;
      .left_star{
        top: 5px;
        position:absolute;
        left: 42px;
        color: #e41135;
      }
      .left_title{
        top: 5px;
        width: 120px;
        font-size: 14px;
        text-align: right;
        position: absolute;
      }
      .user_name{
        width: 240px;
        height: 32px;
        border: 1px #ddddd9 solid;
        position: absolute;
        left: 130px;
        font-size: 14px;
        line-height: 30px;
        padding-left: 10px;
      }
    }
    .photo_bg{
      height: 300px;
      margin-top:20px;  
      position: relative;
      .left_title{
        top: 5px;
        width: 120px;
        font-size: 14px;
        text-align: right;
        position: absolute;
      }
      .updload_bg{
        height: 174px;
        position: absolute;
        left: 130px;
        right: 0;    
        clear: both;
        .item_bg{
          float: left;
          margin-right: 20px;
          .ant-upload.ant-upload-select-picture-card{
            width: 200px;
            height: 125px;
            border-width: 0px;
            margin: 0px;
            padding: 0px;
          }
          .ant-upload.ant-upload-select-picture-card > .ant-upload{
            padding: 0px;
          }
          .upload_img_bg{
            width: 200px;
            height: 125px;
             border: 1px rgba(0, 0, 0, 0.15) solid;  
            border-radius: 4px;
            .upload_img{
              width: 200px;
              height: 125px;
            }
          }
          .qrcode_img{
            width: 100px;
            height: 100px;
            margin-left: 62px;
          }
          .upload_title{
            color: #666666;
            font-size: 14px;
            width: 200px;
            margin-top: 10px;
            text-align: center;
          }
          .qr_info{
            width: 224px;
            text-align: center;
            margin-left: 0px;
            margin-top: 6px;
          }
        }    
        
      }
      .bottom_tip_bg{
        line-height: 22px;
        position: absolute;
        left: 130px;
        top:174px; 
        .tip_info{
          font-size: 14px;
          color: #333333;
          margin-bottom: 5px;
        }
        .submit_btn{
          width: 102px;
          line-height: 32px;
          border-radius: 4px;
          background-color: #e41135;
          margin-top: 35px;
          text-align: center;
          color: white;
          cursor: pointer;
          &:hover{
            opacity: 0.8;
          }
        }
      }
    }
  }
}