.user_center_card_bg{
  width: 990px;
  .card_select_bg{
    height: 68px;
    clear: both;
    .card_select_item_bg{
      margin-left: 53px;
      margin-top: 28px;
      float: left;
      .title_no_color{
        color: #333333;
      }
      .title_red_color{
        color: #e41135;
      }
      .rec_no_color{
        background-color: white;
      }
      .rec_red_color{
        background-color: #e41135;
      }
      .select_item_title{
        font-weight: bold;
        font-size: 18px;
      }
      .select_item_rec{
        margin: 10px 0px 0px 0px;
        height: 2px;
      }
    }
  }
  .display_none{
    display: none;
  }
  .no_data{
    margin: 0px;
    text-align: center;
    color: #333333;
    font-size: 14px;
    line-height: 220px;
  }
  .mid_card_bg{
    width: 950px;
    margin-left: 22px;
    clear: both;
    margin-top: 30px;
    .coupon_item_bg{
      width: 278px;
      height: 150px;
      float: left;
      margin-top: 0px;
      margin-left: 28px;
      margin-bottom: 28px;
      position: relative;
      .rebate_card_bg{
        position: absolute;
      }
      .rebate_content_bg{
        width: 278px;
        height: 150px;
        position: absolute;
        color: white;
        .rebate_title_bg{
          margin: 17px 0px 0px 0px;
          display: flex;
          justify-content: center;
          .rebate_title_left{
            font-size: 14px;
            margin-right: 4px;
            margin-top: 16px;
          }
          .rebate_title_mid{
            font-size:30px;
            font-weight: bold;
          }
          .rebate_title_right{
            font-size:14px;
            font-weight: bold;
            margin-top: 16px;
          }
        }
        .rebate_tip{
          font-size: 12px;
          text-align: center;
          margin: 0px 0px 0px 0px;
        }
        .rebate_valid_date{
          font-size: 12px;
          text-align: center;
          margin: 18px 0px 0px 0px;
        }
        .rebate_valid_desc{
          font-size: 12px;
          text-align: center;
          color: #f9d3b7;
          margin: 5px 0px 0px 0px;
        }
        .rebate_valid_desc_white{
          font-size: 12px;
          text-align: center;
          margin: 5px 0px 0px 0px;
        }
      }
    }
  }
}