.login{
	position: relative;
	.blackBg{
		position: fixed;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.65);
		z-index: 2;
		.resultBox{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 390px;
			height: 240px;
			background: #fff;
			margin: auto;
			text-align: center;
			.resultTitle{
				width: 100%;
				height: 24px;
				line-height: 24px;
				color: #666;
				border-bottom: 1px solid #efefef;
				text-align: left;
				.title{
					margin-left: 10px;
					font-size: 12px;
				}
				img{
					width: 24px;
					float: right;
					cursor: pointer;
					vertical-align: middle;
				}
			}
			.sucIcon{
				margin: 32px 0 14px;
			}
			.sucTitle{
				color: #333;
				font-size: 18px;
			}
			.sucSubTitle{
				.ant-statistic-content{
					position: relative;
					height: 20px;
				}
				.ant-statistic-content-value{
					top: 3px;
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					font-size: 14px;
					color: #666;
				}
			}
			.goHome{
				display: inline-block;
				margin-top: 14px;
				width: 102px;
				height: 32px;
				line-height: 32px;
				background: #E41135;
				color: #fff;
				font-size: 14px;
				border-radius: 4px;
				cursor: pointer;
			}
		}
	}
	.loginHeader{
		.inner{
			height: 102px;
			.loginLogo{
				margin-top: 13px;
				width: 110px;
				height: 80px;
			}
			.headerItem{
				margin-top: 24px;
				float: right;
				display: inline-block;
				margin-left: 52px;
				.headerItemImg{
					vertical-align: middle;
				}
				.headerItemText{
					display: inline-block;
					vertical-align: middle;
					margin-left: 14px;
					.headerItemTextTop{
						font-size: 18px;
						color: #333;
						margin-bottom: 7px;
					}
					.headerItemTextBot{
						font-size: 14px;
						color: #666;
					}
				}
			}
		}
	}
	.loginFooter{
		text-align: center;
		height: 70px;
		line-height: 70px;
		background: #fff;
		.footerRight{
			display: inline-block;
			margin-left: 32px;
			a{
				color: rgba(0, 0, 0, 0.65);
			}
			a:hover{
				color: rgba(0, 0, 0, 0.65);
			}
		}
	}
}