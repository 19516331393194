.Buycart{
	position: relative;
	.jiesuanBox{
		width: 100%;
		height: 64px;
		border: 1px solid #D9D9D9;
		margin-bottom: 30px;
		-webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
		.selAllJiesuan{
			input{
				vertical-align: middle;
				margin-right: 5px;
				margin-top: -2px;
			}
			display: inline-block;
			margin-left: 20px;
			line-height: 64px;
			cursor: pointer;
		}
		.deleteSel{
			margin-left: 50px;
			display: inline-block;
			line-height: 64px;
			cursor: pointer;
			&:hover{
				color: #E41135;
			}
		}
		.collectSel{
			display: inline-block;
			line-height: 64px;
			margin-left: 50px;
			cursor: pointer;
			&:hover{
				color: #E41135;
			}
		}
		.marginL{
			margin-left: 20px;
			display: inline-block;
		}
		.moneyBox{
			float: right;
			text-align: right;
			margin-right: 30px;
			.moneyBoxTop{
				padding-top: 6px;
			}
			.red{
				color: #E41135;
			}
			.big{
				font-size: 20px;
				font-weight: bold;
			}
		}
		.goPay{
			width: 120px;
			line-height: 64px;
			background: #E41135;
			color: #fff;
			text-align: center;
			font-weight: bold;
			float: right;
			font-size: 18px;
			cursor: pointer;
		}
		.disabled{
			background: #D9D9D9;
		}
	}
	.jiesuanFixed{
		display: none;
		border-top: 1px solid #D9D9D9;
		width: 100%;
		background: #fff;
		position: fixed;
		z-index: 3;
		bottom: 0;
		left: 0;
		.jiesuanBox{
			border: none;
			margin: 0;
		}
	}
	.inner{
		.noGoods{
			text-align: center;
			padding: 110px 0;
			img{
				margin-right: 10px;
				vertical-align: middle;
			}
			.noTextBox{
				display: inline-block;
				vertical-align: middle;
				div{
					display: inline-block;
				}
				.noText{
					line-height: 30px;
				}
				.goSee{
					cursor: pointer;
					text-decoration: underline;
					color: #005AA0;
				}
				.noTextBtn{
					text-align: left;
					display: block;
					.goLogin{
						background: #E41135;
						width: 50px;
						height: 20px;
						line-height: 20px;
						color: #fff;
						border-radius: 2px;
						text-align: center;
						cursor: pointer;
						margin-right: 5px;
					}
				}
			}
		}
		.cartTitle{
			font-size: 18px;
			color: #333;
			font-weight: bold;
			line-height: 44px;
		}
		.cartTableHeader{
			margin-bottom: 20px;
			width: 100%;
			background: #F2F2F2;
			height: 32px;
			line-height: 32px;
			.headerItem{
				font-size: 12px;
				display: inline-block;
				input{
					vertical-align: middle;
					margin: -2px 10px 0 20px;
					width: 16px;
					background: #fff;
				}
			}
			.headerSel{
				width: 170px;
			}
		}
		.goodsName{
			width: 491px;
		}
		.goodsPrice{
			position: relative;
			width: 100px;
			.price{
				font-weight: bold;
				color: #333;
				font-size: 14px;
			}
			.goodsPromotion{
				position: relative;
				z-index: 4;
				margin-top: 5px;
				cursor: pointer;
				width: 52px;
				height: 22px;
				line-height: 20px;
				border: 1px solid #E41135;
				color: #E41135;
				text-align: center;
				font-size: 12px;
				border-radius: 1px;
				img{
					margin-left: 2px;
					vertical-align: middle;
				}
			}
			.open{
				border-bottom: 1px solid #fff;
				z-index: 6;
			}
			.promotionChangeBox{
				display: none;
				padding: 16px 10px;
				margin-top: -1px;
				z-index: 5;
				background: #fff;
				border: 1px solid #E41135;
				width: 199px;
				position: absolute;
				input{
					margin-right: 3px;
					vertical-align: middle;
					margin-top: -2px;
				}
				.promotionItem{
					line-height: 26px;
				}
				.promotionBtn{
					margin-top: 14px;
					cursor: pointer;
					display: inline-block;
					width: 50px;
					height: 22px;
					line-height: 20px;
					text-align: center;
					font-size: 12px;
					border-radius: 2px;
				}
				.canclePromotion{
					border: 1px solid #D9D9D9;
					margin: 0 20px 0 15px;
				}
				.surePromotion{
					border: 1px solid #E41135;
					background: #E41135;
					color: #fff;
				}
			}
			.openPro{
				display: block;
			}
		}
		.goodsNum{
			width: 123px;
		}
		.goodsAccount{
			width: 150px;
			.tagPrice{
				margin-top: 5px;
			}
		}
		.cartItem{
			margin-left: 20px;
			margin-bottom: 20px;
			.itemAllSel{
				input{
					margin-top: -2px;
					margin-right: 6px;
					vertical-align: middle;
				}
			}
			.goodsBox{
				.couponSmall{
					display: inline-block;
					margin-right: 10px;
					border-radius: 2px;
					padding: 0 10px;
					height: 20px;
					line-height: 18px;
					border: 1px solid #E41135;
					font-size: 12px;
					position: relative;
					margin-left: 10px;
					cursor: pointer;
					.banyuan{
						background: #FCF7F8;
						width: 4px;
						height: 8px;
						position: absolute;
						top: 5px;
					}
					.left{
						left: -1px;
						border-radius: 0 4px 4px 0;
						border: 1px solid #E41135;
						border-left: none;
					}
					.right{
						right: -1px;
						border-radius: 4px 0 0 4px;
						border: 1px solid #E41135;
						border-right: none;
					}
				}
				.goodsBoxTitle{
					margin-left: 13px;
					padding: 10px 0;
					position: relative;
					.couponBox{
						border: 1px solid #BABABA;
						width: 664px;
						height: 547px;
						background: #fff;
						position: absolute;
						left: 10px;
						top: 40px;
						z-index: 6;
						.couponBoxTitle{
							width: 100%;
							height: 40px;
							background: #F2F2F2;
							font-size: 16px;
							line-height: 40px;
							padding-left: 20px;
							.close{
								margin-top: 4px;
								vertical-align: middle;
								float: right;
								cursor: pointer;
							}
						}
						.couponContent{
							width: 100%;
							height: 506px;
							overflow-y: auto;
							padding: 15px 30px;
							display: inline-block;
							.CouponItemSmall{
								display: inline-block;
							}
						}
					}
					.giftBox{
						left: 0;
						border: 1px solid #BABABA;
						width: 390px;
						height: 400px;
						background: #fff;
						position: absolute;
						left: 10px;
						top: 40px;
						z-index: 6;
						.giftBoxTitle{
							width: 100%;
							height: 40px;
							color: #333333;
							font-size: 16px;
							line-height: 40px;
							padding-left: 20px;
							border-bottom: 1px solid #E5E5E5;
							.tip{
								color: #999999;
								font-size: 12px;
							}
							.close{
								margin-top: 4px;
								vertical-align: middle;
								float: right;
								cursor: pointer;
							}
						}
						.giftContent{
							width: 100%;
							height: 314px;
							overflow-y: auto;
							display: inline-block;
							color: #333;
							.giftItem{
								padding: 10px 30px;
								border-bottom: 1px solid #E5E5E5;
								input{
									display: inline-block;
									vertical-align: top;			
								}
								img{
									margin: 0 10px 0 10px;
									width: 80px;
									height: 80px;
									border: 1px solid #EEEEEE;
									display: inline-block;
									vertical-align: top;
								}
								.giftRight{
									width: 190px;
									height: 80px;
									display: inline-block;
									vertical-align: top;
									.giftTitle{
										height: 60px;
									}
									.giftPrice{
										height: 20px;
										color: #E41135;
									}
								}
							}
						}
						.bottomBtn{
							text-align: center;
							height: 40px;
							background: #F2F2F2;
							.btn{
								margin-top: 10px;
								display: inline-block;
								cursor: pointer;
								width: 50px;
								height: 20px;
								text-align: center;
								line-height: 20px;
								font-size: 12px;
							}
							.sure{
								background: #E41135;
								color: #fff;
								margin-right: 10px;
							}
							.cancle{
								color: #999999;
								border: 1px solid #999999;
							}
						}
					}
					.goodsBoxTitleItem{
						display: inline-block;
					}
					.promotionTag{
						padding: 2px 6px;
						color: #FF6C00;
						border: 1px solid #FF6C00;
						font-size: 12px;
						margin: 0 10px;
						border-radius: 2px;
					}
					.isFull{
						background: #FF6C00;
						color: #fff;
					}
					.promotionDesc{
						color: #999999;
					}
					.promotionDescTip{
						width: 12px;
						height: 12px;
						margin: 0 10px 0 4px; 
						vertical-align: middle;
						cursor: pointer;
					}
					.promotionGetGift{
						padding: 2px 6px;
						background: #E41135;
						color: #fff;
						font-size: 12px;
						border-radius: 2px;
						cursor: pointer;
						position: relative;
					}
					.promotionCoudan{
						color: #005AA0;
						font-size: 12px;
						cursor: pointer;
						margin-left: 20px;
					}
				}
				.goodsItem{
					padding: 20px 0;
					height: 120px;
					margin-left: 23px;
					margin-top: -1px;
					border-bottom: 1px solid #D9D9D9;
					border-top: 1px solid #D9D9D9;
					.goodsImg{
						width: 80px;
						height: 80px;
						border: 1px solid #EEEEEE;
						margin: 0 20px 0 10px;
						position: relative;
						.img{
							width: 100%;
							height: 100%;
						}
						.qiangguang{
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							margin: auto;
							width: 60px;
						}
					}
					.item{
						display: inline-block;
						vertical-align: top;
					}
					.numControl{
						vertical-align: middle;
						display: inline-block;
						height: 24px;
						.btn{
							border: 1px solid #D9D9D9;
							font-size: 20px;
							font-weight: bold;
							color: #777;
							width: 24px;
							height: 24px;
							text-align: center;
							cursor: pointer;
							line-height: 20px;
							-webkit-user-select:none;
					    -moz-user-select:none;
					    -ms-user-select:none;
					    user-select:none;
						}
						.num{
							border-top: 1px solid #D9D9D9;
							border-bottom: 1px solid #D9D9D9;
							height: 24px;
							width: 40px;
						}
						.btn,input{
							color: #333;
							float: left;
							text-align: center;
						}
						.numTip{
							margin-top: 6px;
							float: left;
							color: #E41135;
						}
						.disabled{
							color: #ccc;
						}
					}
					.editBox{
						.delete{
							cursor: pointer;
							&:hover{
								color: #E41135;
							}
						}
						.collect{
							margin-top: 5px;
							cursor: pointer;
							&:hover{
								color: #E41135;
							}
						}
					}
				}
				.selected{
					background: #FFFBF0;
				}
			}
		}
	}
}