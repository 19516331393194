.templateA{
  width: 100%;
  background-color: #F2F2F2;
  height: 490px;
  .inner{
    .recTitle{
      height: 61px;
      line-height: 61px;
      color: #333333;
      font-size: 22px;
      font-weight:bold;
    }
    .recClassify{
      width: 1190px;
      height: 390px;
      .leftArea{
        width: 790px;
        margin-right: 10px;
        float: left;
        .item{
          position: relative;
          float: left;
          height: 390px;
          width: 390px;
          background-color: #fff;
          text-align:center;
          &:nth-of-type(1){
            margin-right: 10px;
          }
          .itemImg{
            display: inline-block;
            width: 100%;
            height: 100%;
          }
          .mainTitle{
            width: 100%;
            position: absolute;
            color: #666666;
            font-size: 18px;
            line-height: 30px;
            text-align: center;
            top:28px;
          }
          .subTitle{
            width: 100%;
            position: absolute;
            text-align: center;
            color:#333333;
            font-size: 22px;
            top:58px;
          }
        }
      }
      .rightArea{
        float: right;
        width: 390px;
        height: 390px;
        .item{
          position: relative;
          width: 100%;
          height: 190px;
          background-color: #fff;
          &:nth-of-type(1){
            margin-bottom: 10px;
          }
          .itemImg{
            display: inline-block;
            width: 100%;
            height: 100%;
          }
          .mainTitle{
            width: 100%;
            position: absolute;
            color: #666666;
            font-size: 18px;
            line-height: 30px;
            top:26px;
            left:20px;
          }
          .subTitle{
            width: 100%;
            position: absolute;
            color:#333333;
            line-height: 38px;
            font-size: 22px;
            top:58px;
            left:20px;
          }
        }
      }
    }
  }
}