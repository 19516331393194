.top_header{
	width: 100%;
	height: 30px;
	line-height: 30px;
	background: #EEECEC;
	color: #333;
  font-size: 12px; 
	.inner{
    height: 30px;
    clear: both;
    .leftInner{
      float: left;
    }
    .welcome{
      float: left;
      margin-right: 15px;
    }
		.marginR{
      margin-right: 15px;
      margin-left: 15px;
      float: left;
    }
    .fl{
      float: left;
    }
    .fr{
      float: right;
    }
		.handle{
      cursor: pointer;
    }
    .mouseOver{
      position: relative;
      &:hover{
        color: #e41135;
        img{
          display: block;
          z-index: 999;
        }
      } 
    }
    .news_num{
      width:12px;
      height: 12px;
      line-height: 13px;
      text-align: center;
      margin-top: 4px;
      margin-left: -2px;
      background-color: #e41135;
      color: white;
      border-radius: 6px;
      font-size: 10px;
    }
    .dropDownMouseOver{
      position: relative;
      padding: 0px 10px 0px 10px;
      &:hover{
        color: #e41135;
        background-color: white;
        div{
          display: block;
        }
      }
    }
    .rightInner{
      float: right;
    }
    .topQrCode{
      width: 120px;
      height: 120px;
      display: none;
      position: absolute;
      top: 30px;
      left: -40px;
    }
    .dropDownImg{
      width:6px;
      height: 4px;
      margin:10px 0px 13px 5px;
    }
    .dropDownList{
      background-color:white;
      width:112px;
      padding-bottom: 10px;
      display: none;
      position: absolute;
      left: 0;
      top: 30;
      z-index: 100;
      .dropItem{
        color: #666666;
        margin-top:5px;
        margin-left: 21px;
        cursor: pointer;
        &:hover{
          color: #e41135;
        }
      }
    }
    .empty_dropdown_news{
      background-color:white;
      width: 159px;
      padding-bottom: 15px;
      display: none;
      position: absolute;
      left: 0px;
      top: 30px;
      z-index: 100;
      font-size: 12px;
      color: #333333;
      .empty_news_img{
        width: 32px;
        height: 32px;
        margin-top: 18px;
        margin-left: 54.5px;
      }
      .empty_news_title{
        margin-top: 10px;
        width: 150px;
        text-align: center;
      }
      .history_msg_title{
        width: 120px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        margin-top: 20px;
        margin-left: 19.5px;
        font-size: 12px;
        color: #333333;
        border-radius: 15px;
        border: 1px solid #999999;
      }
    }

    .newsDropDownList{
      background-color:white;
      width: 263px;
      padding-bottom: 10px;
      display: none;
      position: absolute;
      left: 0;
      top: 30;
      z-index: 600;
      .news_section{
        .more_msg{
          width: 263px;
          height: 70px;
          display: flex;
          justify-content: center;
          .more_msg_title{
            width: 120px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            margin-top: 20px;
            font-size: 12px;
            color: #333333;
            border-radius: 15px;
            border: 1px solid #999999;
          }
        }
        .section_margin_top{
          margin-top: 5px;
          clear: both;
          height: 30px;
        }
        .section_left_line{
          background-color: #e41135;
          width: 2px;
          height: 14px;
          margin-top: 9px;
          margin-left: 16px;
          float: left;
        }
        .section_right_title{
          margin-left: 5px;
          color: #333333;
          font-weight: 800;
          font-size: 14px;
          float: left;
        }
        .news_item{
          width: 263px;
          height: 70px;
          position: relative;
          cursor: pointer;
          &:hover{
            background-color: #f0f0f0;
          }
          .news_item_img{
            width: 50px;
            height: 50px;
            position: absolute;
            top: 9px;
            left: 16px;
          }
          .content{
            position: absolute;
            font-size: 12px;
            color: #333333;
            top: 9px;
            left: 75px;
            right: 16px;
            line-height: 16px;
            &:hover{
              color: #e41135;
            }
          }
          .two_line{
            line-height: 21px;
            margin-top: 4px;
          }
          .sign_for_status{
            color: #333333;
            font-size: 12px;
            position: absolute;
            left: 75px;
            bottom: 15px;
          }
          .new_item_bootom_line{
            width: 168px;
            height: 1px;
            background: #e5e5e5;
            position: absolute;
            left: 76px;
            bottom: 0px;

          }
        }
      }
    }
	}
}