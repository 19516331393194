.get_coupon_pop_back{
    width: 100%;
    height: 100%; 
		position: fixed;
		background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
		top: 0;
		left: 0;
    .get_coupon_content_back{
        width: 316px;
        height: 439px;
        position: absolute;
        top: 50%;
        left: 50%;
				margin-left: -158px;
				margin-top: -220px;
        .get_coupon_top_img{
					height: 157px;
					position:absolute;
					top: 0;
					left: 0;
				}
				.get_coupon_content_red_bg{
					height: 330px;
					width: 316px;
					background-color: #C90425;
					position:absolute;
					bottom: 0;
					left: 0;
					.get_coupon_top_title{
						margin-top: 15px;
						text-align: center;
						color:rgba(255, 255, 255, 0.8);
						font-size: 12px;
					}
					.get_coupon_content_scroll{
						margin-top: 10px;
						height: 224px;
						overflow: scroll;
					}
					.get_coupon_content_scroll::-webkit-scrollbar {
						display: none;
					}
					.get_coupon_item{
						width: 240px;
						height: 75px;
						margin-left: 38px;
						margin-top: 0px;
						background-color: white;
						margin-bottom: 4px;
						.get_coupon_item_price_content{
							margin: 0 auto;
							display: flex;
							justify-content: center;
							.price_tag{
								margin-top: 22px;
								margin-left: 2px;
								font-size: 12px;
								color: #e41135;
							}
							.get_coupon_price{
								font-size: 32px;
								color: #e41135
							}
						}
						.get_coupon_description{
							font-size: 12px;
							color: #808080;
							text-align: center;
						}
					}
					.know_btn{
						margin-top: 15px;
						margin-left: 48px;
						width: 220px;
						height: 38px;
						line-height: 38px;
						text-align: center;
						border: 1px solid #f9e77b;
						border-radius: 19px;
						color: #f9e77b;
					}
				}
    }
}