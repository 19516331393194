.topTabBox{
  width: 100%;
  background-color: #000000FF;
  height: 40px;
  .inner{
    width: 1190px;
    margin: 0 auto;
    height: 40px;
    .TopClassify{
      display: inline-block;
      width: 170px;
      float: left;
      background-color: #E41135FF;
      height: 40px;
      position: relative;
      .toptitle{
        width: 170px;
        height: 40px;
        line-height: 40px;
        padding-left: 40px;
        font-size: 16px;
        color: #FEFEFEFF;
      }
      .topMenu{
        height: 420px;
        width: 170px;
        line-height: 40px;
        position: absolute;
        background-color: #E41135FF;
        top:40px;
        left: 0;
        z-index: 200;
        li{
          display: block;
          width: 170px;
          height: 32.3px;
          padding: 0 10px;
          cursor: pointer;
          .MenuInner{
            border-bottom:1px solid rgba(255,255,255,0.2);
            width: 150px;
            height: 32px;
            line-height: 32px;
            padding-left: 30px;
            position: relative;
            a{
              color: #FEFEFEFF;
            }
            .rightIcon{
              position: absolute;
              right:0px;
              margin-top:9px;
              display: inline-block;
              color: #FEFEFEFF;
              font-size: 14px;
            }
          }
          .secClassify{
            display: none;
            width: 1020px;
            border-right: 1px solid #D9D9D9;
            border-bottom: 1px solid #D9D9D9;
            padding: 17px 57px 3px 29px;
            position: absolute;
            top: 0;
            left: 170px;
            background-color: #FFFFFF;
            height: 420px;
            overflow-y: auto;
            dl{
              display: block;
              overflow: hidden;
              margin-bottom: 24px;
              dt{
                display: block;
                float: left;
                width: 105px;
                text-align: left;
                line-height: 18px;
                a{
                  color: #333333;
                  font-weight: bold;
                  font-size: 12px;
                }
              }
              dd{
                line-height: 18px;
                overflow: hidden;
                a{
                  display: inline-block;
                  margin-right: 20px;
                  font-size: 12px;
                  line-height: 18px;
                  color: #666666;
                }
              }
            }
          }
        }
        li:hover{
          background-color: #fff;
          .secClassify{
            display: block;
          }
          a{
            color: #E41135FF;
          }
          .rightIcon{
            color: #E41135FF;
          }
        }
        li:last-of-type{
          border-bottom:none;
        }
      }
      .isHomePage{
        display: none;
      }
      &:hover{
        .topMenu{
          display: block;
        }
      }
    }
    .topNav{
      display: inline-block;
      .Nav{
        width: 1000px;
        height: 40px;
        line-height: 40px;
        li{
          float: left;
          margin-right: 39px;
          a{
            color: #FEFEFEFF;
            font-weight: bold;
            font-size: 14px;
          }
        }
        li:first-of-type{
          margin-left: 30px;
        }
      }
    }
  }
}