//选项卡
.commonTab{
	float: left;
	line-height: 40px;
	height: 40px;
	background: #F2F2F2;
	.selTypeTabItem{
		position: relative;
		cursor: pointer;
		overflow: hidden;
		background: #F2F2F2;
		border-width: 4px 1px 1px 1px;
		border-color: #F2F2F2;
		border-style: solid;
		font-size: 14px;
		float: left;
		height: 40px;
		line-height: 36px;
		box-sizing:border-box;
		-moz-box-sizing:border-box; /* Firefox */
		-webkit-box-sizing:border-box;
		transition: all .3s ease;
		padding: 0 30px;
		.tag{
			font-size: 12px;
			text-align: center;
			display: block;
			position: absolute;
			line-height: 22px;
			padding: 0 6px;
			height: 22px;
			min-width: 22px;
			top: 1px;
			right: 1px;
			background: #ff3535;
			border-radius: 50%;
			color: #fff;
		}
	}
	.selTypeTabItem:hover{
		background: #fff;
		border-color: #fff;
	}
}