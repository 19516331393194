.userOrder{
  width: 990px;
  padding: 18px 50px;
  color: #333333;
  .selTypeTab{
    .tabItem{
      position: relative;
      width: 100px;
      height: 48px;
      line-height: 24px;
      padding: 12px 0;
      text-align: center;
      float: left;
      font-weight: 700;
      font-size: 18px;
      color: #191e1f;
      cursor: pointer;
      .tag{
        width: 18px;
        height: 18px;
        background-color:#E41135;
        position: absolute;
        border-radius: 9px;
        font-size: 12px;
        color: #fff;
        text-align: center;
        line-height: 18px;
      }
    }
    .inputItem{
      margin-top: 11px;
      width: 240px;
      float: right;
      .searchInput{
        height: 30px;
        padding-left: 15px;
        line-height: 16px;
        width: 200px;
        border: 1px solid #D9D9D9;
      }
      .searchBtn{
        display: inline-block;
        width: 38px;
        height: 30px;
        padding: 2px 6px;
        border: 1px solid #ddd;
        border-left: none;
        background: #F4F4F4;
        color: #333;
        text-decoration: none;
        outline: none;
        text-align: center;
        vertical-align: top;
        cursor: pointer;
        img{
          width: 26px;
        }
      }
    }
  }
  .tableTitle{
    width: 100%;
    height: 32px;
    background-color: #F2F2F2;
    padding: 0 0 9px 0;
    margin-bottom: 20px;
    color: #666666;
    .tableTitleItem{
      float: left;
      width: 110px;
      text-align: center;
      padding-top:7px;
      cursor: pointer;
    }
    .searchTitleItem{
      position: relative;
      width: 430px;
      height: 32px;
      padding-top:0px;
      font-size: 12px;
      line-height: 21px;
      .searchTime{
        position: absolute;
        width: 137px;
        padding: 0 16px;
        height: 32px;
        overflow: hidden;
        span{
          padding-top: 8px;
          display: block;
          height: 32px;
          text-align: left;
          img{
            margin-left: 10px;
            vertical-align: middle;
          }
        }
        &:hover{
          background-color: #fff;
          box-shadow:0px 3px 6px rgba(0,0,0,0.16);
          height: auto;
        }
      }
    }
    .handleTitleItem{
      width: 125px;
    }
  }
  .tableItem{
    width: 100%;
    .myOdr_tab{
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      margin-bottom: 20px;
      .myOdr_tb1{
        width: 100%;
        tr{
          display: table-row;
          width: 100%;
          th{
            display: table-cell;
            border: 1px solid #e8e8e8;
            text-align: left;
            font-weight: 400;
            font-size: 12px;
            color: #999999;
            background-color: #F2F2F2;
            .orderTime{
              float: left;
              margin-left: 20px;
             }
             .orderNum{
               float: left;
               margin-left:80px;
               cursor: pointer; 
               color: #999999;
             }
             .delOrder{
               float: right;
               margin-right: 20px;
               cursor: pointer;
               .delred{
                display: none;
              }
               &:hover{
                 .delwhite{
                   display: none;
                 }
                 .delred{
                   display: inline-block;
                 }
               }
             }
            }
          .bbNone{
            border-bottom: none;
            height: 30px;
            line-height: 30px;
          }
        }
        .aOrder{
          height: 120px;
          .aOrder-items{
            width: 430px;
            border: 1px solid #e8e8e8;
            border-top: 1px solid #e8e8e8;
            .myOdr_tb2{
              tbody{
                tr{
                  td{
                    height: 120px;
                    text-align: center;
                  }
                  .img{
                    padding: 20px 20px;
                    width: 120px;
                    img{
                      width: 80px;
                    }
                  }
                  .goodsTitle{
                    width: 220px;
                    vertical-align: top;
                    cursor: pointer;
                    span{
                      margin-top: 20px;
                      width: 100%;
                      height: 42px;
                      text-align: left;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      a:hover{
                        color: #E41135;
                      }
                    }
                  }
                  .amount{
                    width: 90px;
                    vertical-align: top;
                    span{
                      margin-top: 20px;
                      width: 100%;
                      height: 42px;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                }
              }
            }
          }
          .rbBorder{
            text-align: center;
            vertical-align: top;
            padding-top: 20px;
            width: 110px;
            border-right: 1px solid #e8e8e8;
            border-bottom: 1px solid #e8e8e8;
            font-size: 14px;
            .orderStatus{
              display: block;
              color: #FF6C00;
              line-height: 21px;
            }
            .orderdetail{
              display: block;
              cursor: pointer;
              &:hover{
                color: #E41135;
              }
            }
            .price{
              font-weight: 700;
            }
            .text{
              display: block;
            }
          }
          .handleOrder{
            width: 125px;
            text-align: center;
            span{
              display: block;
            }
            .countDown{
              color: #666666;
              margin-bottom: 10px;
              span{
                display: inline-block;
                line-height: 24px;
              }
              img{
                margin-right: 5px;
                vertical-align: middle;
              }
            }
            .btn{
              width: 70px;
              height: 26px;
              border:1px solid #E41135;
              background-color: #fff;
              border-radius:2px;
              line-height: 26px;
              margin-left: 28px;
              cursor: pointer;
              margin-bottom: 7px;
              color: #E41135;
              &:hover{
                background-color: #E41135;
                color:#fff;
              }
            }
            .text{
              line-height: 21px;
              cursor: pointer;
              &:hover{
                color: #E41135;
              }
            }
            .long{
              width: 98px;
              margin-left: 14px;
            }
          }
        }
      }
    }
  }
  .noOrderBox{
    width: 100%;
    height: 100%;
    text-align: center;
    .noOrder{
      margin-top: 70px;
    }
  }
}