.CouponItemSmall{
  margin: 0 10px 10px 0;
  position: relative;
  width: 280px;
  height: 110px;
  border: 1px solid #BABABA;
  line-height: 20px;
  .yilingqu{
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 26px;
  }
  .couponLeft{
    padding: 10px;
    position: absolute;
    width: 240px;
    height: 108px;
    .symbol{
      display: inline-block;
      font-size: 14px;
      color: #E41135;
    }
    .money{
      font-size: 28px;
      color: #E41135;
      font-weight: bold;
    }
    .tiaojian{
      margin-top: 3px;
      display: inline-block;
      color: #666666;
      font-size: 12px;
    }
    .couponTitle{
      width: 100%;
      font-size: 12px;
      color: #666666;
    }
    .couponTime{
      font-size: 12px;
      color: #666666;
    }
  }
  .couponRight{
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 110px;
    right: 0;
    text-align: center;
    .getNow{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 60px;
      line-height: 20px;
      font-size: 14px;
      color: #E41135;
      display: inline-block;
      width: 14px;
    }
  }
}