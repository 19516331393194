.LoginPage{
	top: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 1002;
	background: rgba(0,0,0,.3);
	.loginBox{
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
		background: #fff;
		width: 350px;
		height: 404px;
		z-index: 1;
		.couponBoxTitle{
			width: 100%;
			height: 40px;
			background: #F2F2F2;
			font-size: 16px;
			line-height: 40px;
			padding-left: 20px;
			.close{
				margin-top: 4px;
				vertical-align: middle;
				float: right;
				cursor: pointer;
			}
		}
		.loginTitle{
			margin-top: 38px;
			margin-bottom: 27px;
			.titleName{
				cursor: pointer;
				display: inline-block;
				width: 174px;
				text-align: center;
				font-size: 18px;
				font-weight: bold;
			}
			.borderR{
				border-right: 1px solid #E5E5E5;
			}
			.active{
				color: #E41135;
			}
		}
		.errTip{
			margin-bottom: 10px;
			width: 270px;
			margin: 0 auto;
			color: #E41135;
			font-size: 12px;
			height: 18px;
			.errIcon{
				vertical-align: middle;
				margin-right: 4px;
			}
		}
		.btn{
			width: 270px;
			margin: 11px auto 12px;
			color: #fff;
			height: 40px;
			background: #E41135;
			border-radius: 4px;
			text-align: center;
			line-height: 40px;
			cursor: pointer;
		}
		.userRule{
			width: 270px;
			margin: 5px auto;
			font-size: 12px;
			color: #666;
			.rule{
				cursor: pointer;
				text-decoration: underline;
			}
		}
		.loginWay{
			width: 270px;
			margin: 5px auto;
			color: #666;
			font-size: 12px;
			.loginRight{
				float: right;
				cursor: pointer;
			}
			.loginWayChange{
				cursor: pointer;
			}
		}
		.selected{
			cursor: pointer;
			margin-right: 5px;
		}
		.loginThird{
			position: absolute;
			display: none;
			width: 100%;
			bottom: 0;
			height: 42px;
			border-top: 1px solid #efefef;
			.thirdItem{
				cursor: pointer;
				width: 50%;
				display: inline-block;
				text-align: center;
				line-height: 42px;
				img{
					vertical-align: middle;
				}
			}
		}
	}
}