.ProductTem{
  position: relative;
  width: 197px;
  height: 288px;
  padding: 20px;
  background-color: #fff;
  .Img{
    position: relative;
    width: 147px;
    height: 147px;
    div{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .labelPrice{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50px;
        height: 16px;
        display: block;
        .ant-statistic{
          font-size: 12px;
          font-weight: bold;
          text-align: center;
          line-height: 8px;
          .ant-statistic-content-prefix{
            display: inline-block;
            color: #FFF;
            font-size: 8px;
            line-height: 16px;
            font-weight: normal;
            vertical-align:bottom;
            margin-right: 0;
          }
          .ant-statistic-content-value{
            height: 16px;
            .ant-statistic-content-value-int{
              color: #FFFFFF;
              font-size: 12px;
            }
            .ant-statistic-content-value-decimal{
              font-size: 8px;
              color: #FFF;
              font-weight: normal;
            }
          }
        }
      }
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .proContent{
    margin-top:10px; 
    .Title{
      font-size: 14px;
      color:#333333;
      line-height: 21px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow:hidden;
      text-overflow:ellipsis;
    }
    .price{
      position: absolute;
      bottom: 38px;
      color: #E41135;
      .symbol{
        vertical-align: middle;
        font-size: 14px;
      }
      .number{
        font-family: Helvetica;
        vertical-align: middle;
        font-size: 20px;
      }
      .discount{
        font-family: Helvetica;
        display: inline-block;
        padding: 0 8px;
        height: 18px;
        vertical-align: middle;
        border-radius:18px;
        background-color: #FFEBEE;
        font-size: 12px;
        margin-left: 4px;
        color: #E41135;
        text-align: center;
        font-weight: bold;
      }
    }
    .special_price{
      position: absolute;
      bottom: 20px;
      text-decoration: line-through;
      color: #999999;
      font-size: 12px;
      line-height: 16px;
      font-family: Helvetica;
    }
  }
}