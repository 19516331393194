.PayCenter{
	background: #F2F2F2;
	.inner{
		padding: 40px 0;
		.orderContent{
			background: #fff;
			padding: 40px 60px;
			.orderContentImg{
				display: inline-block;
			}
			.orderContentRight{
				vertical-align: top;
				margin-left: 10px;
				display: inline-block;
				.orderStatus{
					font-size: 18px;
					font-weight: bold;
					color: #333;
				}
				.orderTime{
					margin-top: 6px;
					font-size: 14px;
					color: #333;
				}
				.userInfo{
					font-size: 13px;
					color: #333;
					padding: 10px 20px;
					margin-top: 30px;
					border: 1px solid #D9D9D9;
					line-height: 30px;
				}
			}
		}
		.payBox{
			margin-top: 30px;
			background: #fff;
			padding: 0px 60px 40px;
			.actPay{
				margin-bottom: 20px;
				line-height: 60px;
				border-bottom: 1px solid #D9D9D9;
				.money{
					color: #E41135;
					font-size: 24px;
					font-weight: bold;
				}
			}
			.payList{
				.payListItem{
					margin-bottom: 20px;
					height: 54px;
					line-height: 54px;
					box-shadow:0px 0px 13px rgba(0,0,0,0.12);
					input{
						margin: 0 20px;
						vertical-align: middle;
					}
					img{
						margin-right: 10px;
					}
				}
			}
			.payNow{
				width: 135px;
				height: 30px;
				line-height: 30px;
				text-align: center;
				color: #fff;
				background: #E41135;
				font-size: 14px;
				cursor: pointer;
				border-radius: 2px;
			}
		}
	}
}
