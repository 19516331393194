.user_manager_bg{
  width: 990px;
  height: 488px;
  margin: 0px 0px 0px 0px;
  color: #333333;
  .head_title{
    margin:0px 50px 0px 50px;
    font-size: 18px;
    height: 80px;
    line-height: 80px;
    font-weight: bold;
  }
  .head_bg{
    position: relative;
    height: 60px;
    .left_title{
      width: 120px;
      font-size: 14px;
      text-align: right;
      position: absolute;
    }
    .ant-upload.ant-upload-select-picture-card{
      width: 60px;
      height: 60px;
      border-width: 0px;
      margin: 0px;
    }
    .head_img_bg{
      width: 60px;
      height: 60px;
      position: absolute;
      left: 120px;
    }
    .head_img{
      width: 60px;
      height: 60px;
      border-radius: 30px;
    }
    .update{
      font-size: 12px;
      margin-top: 44px;
      position: absolute;
      left: 190px;
    }
    .update_input{
      font-size: 12px;
      margin-top: 44px;
      position: absolute;
      left: 180px;
      opacity: 0;
    }
  }
  .error_tip{
    color: #e41135;
    font-size: 12px;
    margin: 5px 0px 0px 130px;
  }
  .info_bg{
    clear: both;  
    margin-top:20px;  
    position: relative;
    height: 32px;
    .left_star{
      top: 5px;
      position:absolute;
      left: 70px;
      color: #e41135;
    }
    .left_title{
      top: 5px;
      width: 120px;
      font-size: 14px;
      text-align: right;
      position: absolute;
    }
    .user_id{
      top: 5px;
      position: absolute;
      left: 130px;
      font-size: 14px;
    }
    .user_name_bg{
      width: 180px;
      height: 32px;
      border: 1px #ddddd9 solid;
      position: absolute;
      left: 130px;
      .user_name{
        font-size: 14px;
        left: 10px;
        line-height: 30px;
        width: 140px;
        position: absolute;
      }
      .check_img{
        width: 16px;
        height: 16px;
        position: absolute;
        right: 10px;
        top: 7px;
      }
    }
    .sex_select_bg{
      position: absolute;
      left: 130px;
      top: 5px;
      clear: both;
      .sex_bg{
        height: 32px;
        float: left;
        margin-right: 20px;
        .circle_big{
          margin-top: 2.5px;
          width: 16px;
          height: 16px;
          border-radius: 8px;
          border: 1px #bababa solid; 
          float: left;
          .circle_small_nomal{
            margin-top: 2.8px;
            margin-left: 2.8px;
            width: 9px;
            height: 9px;
            border-radius: 4.5px;
          }
          .circle_small_select{
            margin-top: 2.8px;
            margin-left: 2.8px;
            width: 9px;
            height: 9px;
            background-color: #e41135;
            border-radius: 4.5px;
          }
        }
        .sex_title{
          margin-left: 4px;
          color: #333333;
          font-size: 14px;
          float: left;
        }
      }
    }
    .yyyymmdd_select_bg{
      position: absolute;
      left: 130px;
      top: 0px;
      clear: both;
      .yyyymmdd_select{
        width: 70px;
        height: 32px;
        float: left;
      }
      .ymd_title{
        width: 34px;
        font-size: 14px;
        color: #333333;
        float: left;
        line-height: 32px;
        text-align: center;
      }
    }
  }
  .submit_btn{
    background-color: #e41135;
    width: 102px;
    height: 32px;
    color: white;
    line-height: 32px;
    text-align: center;
    margin-top: 40px;
    border-radius: 4px;
    margin-left: 130px;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
  }
}