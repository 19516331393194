.PayZFB{
	background: #F2F2F2;
	.zfbPay{
		height: 110px;
		background: #fff;
		img{
			margin-top: 25px;
			margin-right: 20px;
		}
		.myPay{
			vertical-align: bottom;
		}
	}
	.inner{
		padding-bottom: 70px;
		.moneyBox{
			height: 86px;
			line-height: 86px;
			.money{
				float: right;
				color: #FF6C00;
				font-size: 20px;
			}
		}
		.qCodeBox{
			padding: 70px 0;
			height: 478px;
			text-align: center;
			background: #fff;
			.money{
				color: #FF6C00;
				font-size: 24px;
			}
			.qCode{
				display: inline-block;
				width: 204px;
				border: 1px solid #D9D9D9;
				box-shadow:0px 2px 4px rgba(0,0,0,0.1);
				.saoyisao{
					display: inline-block;
					.saoyisaoText{
						font-size: 12px;
						display: inline-block;
						vertical-align: middle;
						margin-left: 8px;
					}
				}
				.h5Content{
					overflow: hidden;
					width: 204px;
					height: 208px;
				}
			}
		}
	}
}
