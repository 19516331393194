.Sidebar{
  width: 80px;
  z-index: 1000;
  position: relative;
  top:40px;
  .linkBox{
    position: relative;
    width: 80px;
    height: 70px;
    border: 1px solid #F2F2F2;
    background-color: #fff;
    cursor: pointer;
    &:hover{
      background-color: #f9f9f9;
    }
    &:hover .QRcode{
      display: block;
	  img{
		  width:120px;
		  height: 120px;
	  }
	 
    }
    .linkImg{
      margin:10px 25px 0 25px;
    }
    .title{
      font-size: 14px;
      color: #666666;
      width: 100%;
      text-align: center;
      cursor: pointer;
    }
    .QRcode{
      position: absolute;
      top:-50px;
      left:-160px;
      display: none;
    }
  }
  .Invite{
    position: absolute;
    top:-77px;
    right:63px;
  }
}