.review{
  background-color: #F2F2F2;
  color: #333333;
  .guideTitle{
    height: 50px;
    line-height: 50px;
    background-color: #F2F2F2;
    font-size: 12px;
    .homePage{
      a:hover{
        border-bottom:1px solid #333333;
      }
    }
    .userCenter{
      a:hover{
        border-bottom:1px solid #333333;
      }
    }
  }
  .detailBox{
    background-color: #fff;
    padding: 30px 50px 50px 50px;
    .title1{
      font-size: 18px;
      font-weight: Bold;
    }
    .goodsreview{
      width: 100%;
      height: 400px;
      border: 1px solid #D9D9D9;
      margin-top: 30px;
      .goodsInfo{
        width: 345px;
        height: 398px;
        border-right: 1px solid #D9D9D9;
        float: left;
        padding: 103px 67px;
        text-align: center;
        span{
          display: block;
        }
        .img{
          width: 100px;
          margin: 0 0 20px 56px;
          img{
            width: 100%;
          }
        }
        .title{
          width: 210px;
          font-size: 12px;
          line-height: 21px;
          text-align: left;
          margin-bottom: 4px;
        }
      }
      .comment{
        width: 743px;
        height: 400px;
        float: right;
        padding: 30px 50px 0 50px;
        .grade{
          margin-bottom: 20px;
          span{
            display: inline-block;
          }
          .title{
            font-size: 12px;
            vertical-align: middle;
          }
          .ant-rate{
            color: #FF6C00;
            margin-left: 10px;
            height: 20px;
            li{
              font-size: 18px;
            }
          }
        }
        .text{
          margin-bottom: 5px;
          .title{
            width: 60px;
            height: 32px;
            display: inline-block;
            font-size: 12px;
            vertical-align: top;
            span{
              display:block;
            }
            .red{
              color:#E41135;
              margin-left: 15px;
            }
          }
          textarea{
            width: 575px;
            height: 100px;
            border: 1px solid #D9D9D9;
            margin-left: 6px;
            padding: 10px;
          }
          .notice{
            margin-left: 70px;
            line-height: 16px;
            vertical-align: middle;
            .remove{
              display: inline-block;
              width: 16px;
              height: 16px;
              border-radius: 16px;
              background-color:#E41135;
              padding: 0 2px;
              vertical-align: bottom;
              margin-right: 5px;
              cursor: pointer;
              i{
                margin: 7px 0 0 1px;
                display: block;
                width: 10px;
                height: 2px;
                background-color: #fff;
              }
            }
          }
        }
        .uploading{
          width: 100%;
          .title{
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
            font-size: 12px;
          }
          .uploadImg{
            display: inline-block;
            width: 560px;
            .ant-upload-picture-card-wrapper{
              .ant-upload-list{
                .ant-upload-list-item{
                  width: 70px;
                  height: 70px;
                }
              }
              .ant-upload{
                position: relative;
                width: 70px;
                height: 68px;
                .ant-upload-text{
                  font-size: 12px;
                }
                .limit{
                  position: absolute;
                  right: -100px;
                  width: 95px;
                  font-size: 12px;
                  color: #999999;
                }
              }
            }
          }
        }
        .submitBtn{
          width:102px;
          height:32px;
          background-color: #E41135;
          border-radius:4px;
          color: #FFFFFF;
          text-align: center;
          line-height: 20px;
          padding-top: 6px;
          cursor: pointer;
          margin-left: 70px;
          margin-top: 30px;
        }
      }
    } 
  }
}