.applyForServe{
  background-color: #F2F2F2;
  color: #333333;
  .guideTitle{
    height: 50px;
    line-height: 50px;
    background-color: #F2F2F2;
    font-size: 12px;
    .homePage{
      a:hover{
        border-bottom:1px solid #333333;
      }
    }
    .userCenter{
      a:hover{
        border-bottom:1px solid #333333;
      }
    }
  }
  .detailBox{
    background-color: #fff;
    padding: 30px 50px 50px 50px;
    .title1{
      font-size: 18px;
      font-weight: Bold;
    }
    .refundgoods{
      width: 100%;
      height: 120px;
      border: 1px solid #D9D9D9;
      margin: 30px 0;
      div{
        display: inline-block;
      }
      .img{
        width: 80px;
        margin: 20px 20px;
        img{
          width: 80px;
          height: 80px;
        }
      }
      .title{
        width: 297px;
        height: 48px;
        display: inline-block;
        vertical-align: middle;
        span{
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .count{
        margin: 0 380px 0 60px;
      }
      .numControl{
        vertical-align: middle;
        display: inline-block;
        height: 30px;
        .btn{
          border: 1px solid #D9D9D9;
          font-size: 20px;
          font-weight: bold;
          color: #777;
          width: 30px;
          height: 30px;
          text-align: center;
          cursor: pointer;
          line-height: 26px;
        }
        .disabled{
          color: #ccc;
        }
        .num{
          border-top: 1px solid #D9D9D9;
          border-bottom: 1px solid #D9D9D9;
          height: 30px;
          padding-left: 5px;
          width: 60px;
        }
        div,input{
          color: #333;
          float: left;
          text-align: center;
        }
      }
    }
    .servereason{
      .red{
        color: #E41135;
      }
      .select{
        width: 190px;
        height: 32px;
        padding:0 0 0 10px;
        margin-left: 10px;
        appearance:none;
        -moz-appearance:none;
        -webkit-appearance:none;
        -ms-appearance:none;
        border:1px solid #D9D9D9;
        background: url("../../images/ic_more_gray.png") no-repeat 170px 10px transparent;
        background-size:8px 4px;
        background:#fff\9;
        outline:none;
        .first{
          color: #999999;
        }
      }
    }
    .describe{
      margin-top:30px;
      .title{
        vertical-align: top;
        .red{
          color: #E41135;
        }
      }
      .text{
        width: 540px;
        height: 100px;
        border: 1px solid #D9D9D9;
        margin-left: 6px;
      }
      .limit{
        margin-left: 10px;
        color: #999999;
        font-size: 12px;
      }
    }
    .uploading{
      margin: 30px 0 50px 0;
      .title{
        display: inline-block;
        vertical-align: top;
        margin-right: 15px;
      }
      .uploadImg{
        display: inline-block;
        width: 800px;
        .ant-upload-picture-card-wrapper{
          .ant-upload-list{
            .ant-upload-list-item{
              width: 70px;
              height: 70px;
            }
          }
          .ant-upload{
            position: relative;
            width: 70px;
            height: 68px;
            .ant-upload-text{
              font-size: 12px;
            }
            .limit{
              position: absolute;
              right: -100px;
              width: 95px;
              font-size: 12px;
              color: #999999;
            }
          }
        }
      }
    }
    .submitBtn{
      width:102px;
      height:32px;
      background-color: #E41135;
      border-radius:4px;
      color: #FFFFFF;
      text-align: center;
      line-height: 20px;
      padding-top: 6px;
      cursor: pointer;
      margin-left: 80px;
    }
  }
}