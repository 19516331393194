.user_center_coupon_bg{
  // height: 1200px;
  width: 990px;
  .coupon_select_bg{
    height: 68px;
    clear: both;
    .coupon_select_item_bg{
      margin-left: 53px;
      margin-top: 28px;
      float: left;
      .title_no_color{
        color: #333333;
      }
      .title_red_color{
        color: #e41135;
      }
      .rec_no_color{
        background-color: white;
      }
      .rec_red_color{
        background-color: #e41135;
      }
      .select_item_title{
        font-weight: bold;
        font-size: 18px;
      }
      .select_item_rec{
        margin: 10px 0px 0px 0px;
        height: 2px;
      }
    }
  }
  .coupon_bg_display_none{
    display: none;
  }
  .no_data{
    margin: 0px;
    text-align: center;
    color: #333333;
    font-size: 14px;
    line-height: 220px;
  }
  .mid_coupon_bg{
    // height: 1070px;
    width: 950px;
    margin-left: 20px;
    clear: both;
    margin-top: 30px;
    .coupon_item_bg{
      width: 200px;
      height: 230px;
      float: left;
      margin-top: 0px;
      margin-left: 30px;
      margin-bottom: 30px;
      border: 1px #d9d9d9 solid;
      .copon_head_bg{
        width: 200px;
        height: 100px;
        margin-left:-1px;
        position: relative;
        .coupon_type_img{
          position:absolute;
        }
        .price_bg{
          top: 10px;
          color:white;
          font-weight: bold;
          position:absolute;
          display: flex;
          justify-content: center;
          width: 200px;
          .price_tag{
            font-size: 18px;
            margin-top: 28px;
          }
          .price_content{
            font-size: 42px;
          }
        }
        .req_desc{
          top: 70px;
          width: 200px;
          position:absolute;
          font-size: 14px;
          color: white;
          margin: 0px 0px 15px 0px;
          text-align: center;
        }
      }
      .type_desc{
        margin: 26px 21px 0px 21px;
        color: #333333;
        font-size: 12px;
      }
      .range{
        margin: 10px 21px 0px 21px;
        color: #333333;
        font-size: 12px;
      }
      .valid_date{
        margin: 20px 21px 0px 21px;
        color: #999999;
        font-size: 11px;
      }
    }
  }
}