.Invitegift{
  position: relative;
  cursor: default;
  .InviteTitle{
    font-size: 18px;
    color: #FFFFFF;
    line-height: 24px;
    z-index: 4;
    position: absolute;
    top:96px;
    left:259px;
  }
  .InviteBGWImg{
    position: absolute;
    top:131px;
    left:99px;
    z-index: 2;
  }
  .InviteBGYImg{
    position: absolute;
    top:114px;
    left:84px;
    z-index: 3;
  }
  .InviteClose{
    position: absolute;
    top:77px;
    right: 87px;
    cursor: pointer;
    z-index: 3;
  }
  .getMoney{
    width: 100%;
    position: absolute;
    top:155px;
    text-align: center;
    z-index: 4;
    color: #E41135;
    span{
      font-size: 20px;
      line-height: 58px;
    }
    .bigWord{
      margin-right: 4px;
      font-size: 48px;
      font-family: Tahoma;
      font-weight: bold;
    }
    .symbol{
      font-family: Tahoma;
      font-weight: bold;
      font-size: 20px;
    }
  }
  .InviteContent{
    position: absolute;
    top:250px;
    left:99px;
    width: 396px;
    height: 345px;
    z-index: 4;
    padding: 6px 6px 20px 6px;
    .ContentTitle{
      width: 100%;
      line-height: 18px;
      span{
        display: block;
        width: 100%;
        font-size: 12px;
        text-align: center;
        color: #666666;
        &:nth-of-type(1){
          margin-bottom: 10px;
        }
      }
    }
    .shareUrl{
      width: 328px;
      height: 24px;
      border: 1px solid #D9D9D9;
      border-radius: 2px;
      font-size: 12px;
      text-align: center;
      margin: 10px 0 10px 20px;
    }
    .inviteBottom{
      width: 112px;
      height: 24px;
      line-height: 24px;
      color: #E41135;
      font-size: 14px;
      border: 1px solid #E41135;
      border-radius:25px;
      text-align: center;
      margin-left: 133px;
      cursor: pointer;
    }
    .ViewRules{
      display: block;
      width: 100%;
      margin: 12px 0 8px 0;
      color:#999999;
      text-align: center;
      font-family: SimSun;
      cursor: pointer;
    }
    .rulesText{
      width: 328px;
      height: 149px;
      margin-left: 14px;
      line-height: 18px;
      font-size: 12px;
      span{
        text-align: left;
        color:#707070;
        font-family: SimSun;
      }
    }
  }
}