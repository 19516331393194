.ProductItemRecormend{
  position: relative;
  width: 197px;
  height: 313px;
  background-color: #fff;
  cursor: pointer;
  .tag{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
  }
  .sortTagBox{
    font-size: 18px;
    font-weight: 400;
    width: 26px;
    height: 26px;
    color: #fff;
    line-height: 26px;
    text-align: center;
    position: absolute;
    background-image: url(../../images/bg_tag_short.png);
    top: 10px;
    left: 10px;
    z-index: 5;
  }
  .Img{
    position: relative;
    width: 100%;
    height: 168px;
    div{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .labelPrice{
        position: absolute;
        bottom: 0;
        right: 25px;
        width: 50px;
        height: 16px;
        display: block;
        .ant-statistic{
          font-weight: bold;
          text-align: center;
          line-height: 10px;
          .ant-statistic-content-prefix{
            display: inline-block;
            color: #FFF;
            font-size: 8px;
            line-height: 18px;
            font-weight: normal;
            vertical-align:bottom;
            margin-right: 0;
          }
          .ant-statistic-content-value{
            height: 16px;
            .ant-statistic-content-value-int{
              color: #FFFFFF;
              font-size: 12px;
            }
            .ant-statistic-content-value-decimal{
              font-size: 8px;
              color: #FFF;
              font-weight: normal;
            }
          }
        }
      }
      .proImg{
        margin: 20px 25px 0px 24px;
        width: 148px;
        height: 148px;
      }
    }
    .stockNone{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
  .name{
    margin: 10px 20px 0px 20px;
    font-size: 12px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .price_bg{
    margin:8px 20px 0px 20px;
    clear: both;
    height: 24px;
    .price{
      color: #e41135;
      font-size: 15px;
      font-weight: bold;
      float: left;
    }
    .dicount{
      margin: 2px 0px 0px 4px;
      height: 18px;
      background-color: #ffebee;
      color: #e41135;
      border-radius: 9px;
      padding: 0px 9px 0px 9px;
      font-size: 12px;
      float: left;
    }
  }
  .special_price{
    font-size: 12px;
    color: #ff6c00;
    margin:2px 20px 0px 20px;
    height: 16px;
    line-height: 16px;
  }
  .comment{
    font-size: 12px;
    color: #666666;
    margin:4px 20px 0px 20px;
  }
}