.more_news_super_back{
	width: 100%;
	height: 840px;
	display: flex;
	justify-content: center;
	background-color: #f2f2f2;
	.left_slector_back{
		margin-top: 20px;
		width: 190px;
		height: 800px;
		background-color: white;
		.left_slector{
			width: 188px;
			height: 50px;
			text-align: center;
			line-height: 50px;
			font-size: 14px;
		}
		.left_selector_color_gray{
			color: #333333;
			font-weight: 100
		}
		.left_selector_color_red{
			color: #e41135;
			font-weight: bold;
		}
		.left_division_line{
			width: 150px;
			height: 1px;
			background-color: #e5e5e5;
			margin-left: 15px;
		}
	}
	.right_news_list_back{
		width: 990px;
		height: 780px;
		margin-left: 20px;
		margin-top: 20px;
		background-color: white;
		.right_news_list_title{
			width: 988px;
			height: 60px;
			line-height: 60px;
			text-align: center;
			color: #333333;
			font-size: 18px;
		}
		.right_news_division_line{
			width:910px;
			height: 1px;
			margin-left: 40px;
			background-color: #e5e5e5;
		}
		.right_news_list_content_back{
			width: 990px;
			height: 688px;
			overflow: scroll;
			overflow-x: hidden;
			.news_item_back{
				width: 790px;
				margin-left: 100px;
				.news_item_title{
					width: 790px;
					margin-top: 30px;
					color: #333333;
					font-size: 12px;
					text-align: center;
				}
				.item_content_back{
					width: 790px;
					background-color: #f3f3f3;
					border-radius: 2px;
					margin-top: 23px;
					position: relative;
					padding-bottom: 20px;
					.item_content_title_back{
						width: 788px;
						height: 52px;
						clear: both;
						.left_red_rectangle{
							width: 2px;
							height: 14px;
							margin-top: 20px;
							margin-left: 20px;
							background-color: #e41135;
							float: left;
						}
						.right_content_title{
							margin-top: 16px;
							margin-left: 7px;
							color: #333333;
							font-size: 16px;
							float: left;
						}
					}
					.display_none{
						display: none;
					}
					.display_block{
						display: block;
					}
					.right_conten_division_line{
						width: 750px;
						height: 1px;
						background-color: #d9d9d9;
						margin-left: 20px;
					}
					.right_item_content{
						margin: 20px 20px 0px 20px;
						color: #333333;
						line-height: 24px;
						font-size: 14px;
					}
					.look_news_detail{
						position: absolute;
						right: 20px;
						bottom: 15px;
						clear: both;
						.look_dews_detail_title{
							color: #005aa0;
							font-size: 12px;
							float: left;
						}
						.look_news_detail_arrow_right{
							width: 6px;
							height: 11px;
							margin-left: 5px;
							margin-top: 3.5px;
							float: left;
						}
					}
					.news_goods_models_back{
						margin-top:20px;
						height: 100px;
						clear: both;
						.news_goods_img{
							width: 100px;
							height: 100px;
							background-color: aqua;
							float: left;
						}
						.news_goods_des_back{
							margin-left: 15px;
							width: 600px;
							float: left;
							.news_goods_title{
								color: #333333;
								height: 48px;
							}
							.news_price_back{
								margin-top:27px;
								margin-left: 0px;
								clear: both;
								.news_wuliu_num{
									color: #666666;
									font-size: 12px;
									float: left;
								}
								.news_refundMoney_goods_price{
									color: #e41135;
									font-size: 14px;
									float: left;
								} 
								.news_goods_price{
									color: #e41135;
									font-size: 18px;
									float: left;
								} 
								.news_original_goods_price{
									color: #999999;
									font-size: 12px;
									margin-left: 4px;
									float: left;
									text-decoration: line-through;
								} 
							}
						}
					}
					.direct_news_bottom_bg{
						width: 750px;
						margin-top: 0px;
						.direct_top_division_line{
							width: 750px;
							height: 1px;
							background-color: #d9d9d9;
							margin-top: 20px;
							margin-bottom: 10px;
						}
						.tip_info{
							color: #333333;
							font-size: 12px;
							margin-top: 5px;
						}
					}
				}
			}
		}
	}
}