.orderDetail{
  background-color: #F2F2F2;
  color: #333333;
  .guideTitle{
    height: 50px;
    line-height: 50px;
    background-color: #F2F2F2;
    font-size: 12px;
    .homePage{
      a:hover{
        border-bottom:1px solid #333333;
      }
    }
    .userCenter{
      a:hover{
        border-bottom:1px solid #333333;
      }
    }
  }
  .detailBox{
    background-color: #fff;
    padding: 30px 50px 50px 50px;
    .title1{
      font-size: 18px;
      font-weight: Bold;
    }
    .orderStatus{
      width: 100%;
      height: 220px;
      border:1px solid #D9D9D9;
      margin: 30px 0 20px 0;
      span{
        display: block;
      }
      .status_left{
        position: relative;
        float: left;
        padding: 20px;
        width: 345px;
        border-right: 1px solid #D9D9D9;
        height: 218px;
        .text{
          line-height: 19px;
          margin-bottom: 2px;
        }
        .status{
          color: #43A115;
          font-size: 24px;
          line-height: 29px;
          margin: 12px 0 15px 0;
          font-weight: bold;
        }
        .smallText{
          line-height: 16px;
          font-size: 10px;
          color: #FF6C00;
        }
        .status_bottom{
          position: absolute;
          bottom: 10px;
          width: 305px;
          span{
            display: inline-block;
          }
          .refund{
            float: left;
            cursor: pointer;
            i{
              display: inline-block;
              width:16px;
              height:16px;
              border:1px solid #666666;
              border-radius:50%;
              font-size: 10px;
              line-height: 14px;
              color: #666666;
              margin-right: 4px;
              text-align: center;
              vertical-align: middle;
            }
          }
          .share{
            float: right;
            margin-right: 31px;
            cursor: pointer;
          }
        }
      }
      .status_right{
        position: relative;
        float: right;
        height: 218px;
        width: 743px;
        .progressBar{
          position: absolute;
          top:69px;
          left:52px;
        }
        .refundText{
          text-align: center;
          line-height: 30px;
          margin-bottom: 20px;
        }
        .orderCancal{
          text-align: center;
          height: 198px;
          padding-top:38px;
          .text{
            font-weight: bold;
            line-height: 24px;
            margin-top: 8px;
          }
          .buyAgain{
            width: 102px;
            height: 32px;
            border:1px solid #E41135;
            color: #E41135;
            line-height: 24px;
            border-radius:4px;
            text-align: center;
            padding-top: 4px;
            margin-left: 322px;
            cursor: pointer;
          }
        }
        .non-payment{
          text-align: center;
          height: 218px;
          padding-top:26px;
          .text{
            margin: 8px 0 0 0px;
          }
          .payment{
            width: 102px;
            height: 32px;
            border:1px solid #E41135;
            color: #fff;
            background-color: #E41135;
            line-height: 24px;
            border-radius:4px;
            text-align: center;
            padding-top: 4px;
            margin-left: 322px;
            cursor: pointer;
          }
          .longtext{
            margin-bottom: 28px;
            .ant-statistic{
              display: inline-block;
            }
          }
        }
      }
    }
    .address{
      width: 100%;
      height: 258px;
      border: 1px solid #D9D9D9;
      .addressInfo{
        float: left;
        padding: 20px;
        width: 345px;
        height: 256px;
        border-right: 1px solid #D9D9D9;
        span{
          display: block;
        }
        .receiver{
          font-size: 14px;
          color: #333333;
          font-weight: bold;
          line-height: 22px;
          margin-bottom: 20px;
        }
        .text{
          line-height: 24.5px;
          margin-bottom: 10px;
          span{
            vertical-align: top;
            display: inline-block;
          }
          .receAddress{
            width: 224px;
          }
        }
      }
      .logistics{
        float: right;
        height: 256px;
        width: 743px;
        padding: 20px 0 0 21px;
        .titleitem{
          .title{
            font-weight: bold;
            margin-right: 30px;
          }
          .logscompany{
            margin-right: 30px;
            width: 154px;
            .green{
              color: #52C41A;
            }
          }
        }
        .content{
          height: 204px;
          background-color: #fff;
          margin-top: 10px;
          .noInfo{
            text-align: center;
            img{
              margin-bottom: 10px;
            }
            span{
              display: block;
            }
          }
          ul{
            width: 100%;
            height: 184px;
            overflow-y: auto;
            border-bottom: 1px solid #D9D9D9;
            li{
              height: auto;
              min-height: 39px;
              line-height:20px;
              border-left: 1px solid #d9d9d9;
              margin-left: 10px;
              span{
                display: inline-block;
                vertical-align: top;
              }
              &:before{
                content: '';
                background-color: #D9D9D9;
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 5px;
                margin-right: 10px;
                margin-left: -5px;
                line-height: 18px;
                vertical-align: top;
              }
              .time{
                margin-right: 20px;
                line-height:16px;
                vertical-align: top;
              }
              .text{
                width: 485px;
                line-height: 18px;
                vertical-align: top;
              }
            }
            .last{
              border-left: none;
              &:before{
                content: '';
              }
            }
            .frist{
              &:before{
                content: '';
                background-color: #43A115;
              }
            }
          }
        }
      }
    }
    .orderInfo{
      border:1px solid #D9D9D9;
      margin-top: 20px;
      width: 100%;
      .tableTitle{
        background-color:#F2F2F2;
        height: 32px;
        width: 100%;
        font-size: 12px;
        color: #666666;
        text-align: center;
        line-height: 32px;
        .clearfix{
          width: 100%;
          .tableTitleItem{
            float: left;
            width: 19%;
          }
          .long{
            width: 43%;
          }
        }
      }
      .tableItem{
        width: 100%;
        padding: 0px 27px 0 20px;
        table{
          width: 100%;
          tbody{
            width: 100%;
            tr{
              height: 136px;
              width: 100%;
              text-align: center;
              border-bottom: 1px solid#E5E5E5;
              .goodItem{
                width: 19%;
              }
              .img{
                width: 11%;
                text-align: left;
                img{
                  width: 96px;
                }
              }
              .title{
                width: 32%;
                cursor: pointer;
                span{
                  text-align: left;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  a:hover{
                    color: #E41135;
                  }
                }
              }
              .count{
                width: 20.5%;
              }
              .handle{
                span{
                  display: block;
                  margin-left: 25px;
                  margin-bottom: 6px;
                  cursor: pointer;
                  &:hover{
                    color: #E41135;
                  }
                }
              }
            }
          }
        }
      }
    }
    .orderCost{
      padding: 32px 40px;
      text-align: right;
      font-size: 12px;
      p{
        line-height: 16px;
        margin-bottom: 10px;
      }
      .leftTitle{
        float: right;
        width: 100px;
        .red{
          color: #E41135;
        }
      }
      .explain{
        float: right;
        p{
          cursor: pointer;
          height: 16px;
          line-height: 5px;
          margin-bottom: 10px;
          .icon{
            display: block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: 1px solid #007EDF;
            text-align: center;
            line-height: 12px;
            color: #007EDF;
            font-size: 10px;
            font-family:Helvetica;
            margin-top: 2px;
          }
          .none{
            border: transparent;
            color: transparent;
          }
          .ant-tooltip-inner{
            .leftTitle1{
              width: 188px;
              padding: 0 20px; 
              margin-top: 10px;
              .left{
                float: left;
                text-align: left;
              }
              .right{
                float: right;
                text-align: right;
                color: #E41135;
              }
            }
          }
        }
      }
      .leftContent{
        float: right;
        width: 120px;
        .red{
          color: #E41135;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
  .applyForAfterSalesBox{
    display: none;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    font-size: 14px;
    .sureAndCancelInner{
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 400px;
      height: 281px;
      border-radius: 2px;
      background-color: #ffffff;
      box-shadow:0px 0px 18px rgba(0,0,0,0.25);
      .title{
        width: 100%;
        height: 40px;
        line-height: 40px;
        position: relative;
        border-bottom: 1px solid #E5E5E5;
        .boxName{
          margin-left: 14px;
          vertical-align: middle;
        }
        .closeBtn{
          cursor: pointer;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 14px;
          margin: auto;
        }
      }
      .section{
        position: absolute;
        bottom: 0;
        height: 240px;
        width: 100%;
        top: 41px;
        padding: 60px 0 0 38px;
        .reason{
          .red{
            color: #E41135;
          }
          .select{
            width: 208px;
            height: 32px;
            padding:0 14px 0 10px;
            margin-left: 10px;
            appearance:none;
            -moz-appearance:none;
            -webkit-appearance:none;
            -ms-appearance:none;
            border:1px solid #D9D9D9;
            background: url("../../images/ic_more_gray.png") no-repeat 190px 10px transparent;
            background-size:8px 4px;
            // background:#fff\9;
            outline:none;
            .first{
              color: #999999;
            }
          }
        }
        .opBtn{
          width: 100%;
          position: absolute;
          bottom: 50px;
          left:0px;
          text-align: center;
          .cancel{
            margin: 0 10px;
            cursor: pointer;
            padding: 6px 0 0 0;
            text-align: center;
            display: inline-block;
            width: 88px;
            height: 32px;
            border-radius: 4px;
            background-color: #fff;
            border: 1px solid #D9D9D9;
            line-height: 20px;
            color: #666666;
          }
          .sure{
            margin: 0 10px;
            cursor: pointer;
            padding: 6px 0 0 0;
            line-height: 20px;
            text-align: center;
            color: #fff;
            display: inline-block;
            width: 88px;
            height: 32px;
            border-radius: 4px;
            background-color: #E41135;
            border:1px solid rgba(228,17,53,1);
          }
        }
      }
    }
  }
}